import { userStore } from "@/store/UserStore";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { db, firebase } from "@/common/firebase";
import { UserLayerModel } from "@/shared/model/UserLayerModel";

interface UserLayers {
  [id: string]: UserLayerModel;
}

@Module({ dynamic: true, store, name: "userLayerStore" })
class UserLayerStore extends VuexModule {
  userLayers_uls: UserLayers = {};
  snapshotUnsubscriber_uls: (() => void) | null = null;

  @Action({ rawError: true })
  async initUserLayers_uls(): Promise<void> {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_uls) return;
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: userLayerStore");

    const selfId = userStore.currentUser_us.id;

    try {
      // 初回取得
      const baseQuery = db.collection(`layers/${selfId}/userLayers`);

      let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
      const snapshots = (await baseQuery.get()).docs;
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const userLayer = snapshot.data() as UserLayerModel;
        const userId = snapshot.id;

        self.setUserLayer_uls({ userId, userLayer });
        userStore.adjustUserLayer_us({ userId, userLayer });

        if (maxUpdatedAt === null || userLayer.updatedAt > maxUpdatedAt) {
          maxUpdatedAt = userLayer.updatedAt;
        }
      }

      // 変更監視
      const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

      self.setSnapshotUnsubscriber_uls({
        snapshotUnsubscriber: observeQuery.onSnapshot(function(snapshot) {
          snapshot.docChanges().forEach(function(x) {
            const userLayer = x.doc.data() as UserLayerModel;
            const userId = x.doc.id;

            if (x.type === "added") {
              self.setUserLayer_uls({ userId, userLayer });
              userStore.adjustUserLayer_us({ userId, userLayer });
            } else if (x.type === "modified") {
              self.setUserLayer_uls({ userId, userLayer });
              userStore.adjustUserLayer_us({ userId, userLayer });
            } else if (x.type === "removed") {
              self.unsetUserLayer_uls({ userId });
              userStore.adjustUserLayer_us({ userId, userLayer: null });
            }
          });
        }),
      });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  @Action({ rawError: true })
  destroy_uls() {
    if (this.snapshotUnsubscriber_uls) {
      this.snapshotUnsubscriber_uls();
    }

    this.setSnapshotUnsubscriber_uls({ snapshotUnsubscriber: null });
    this.setUserLayers_uls({ userLayers: {} });
  }

  @Mutation
  private setUserLayers_uls(p: { userLayers: UserLayers }) {
    this.userLayers_uls = p.userLayers;
  }

  @Mutation
  private setUserLayer_uls(p: { userId: string; userLayer: UserLayerModel }) {
    this.userLayers_uls[p.userId] = p.userLayer;
  }

  @Mutation
  private unsetUserLayer_uls(p: { userId: string }) {
    delete this.userLayers_uls[p.userId];
  }

  @Mutation
  private setSnapshotUnsubscriber_uls(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_uls = p.snapshotUnsubscriber;
  }
}

export const userLayerStore = getModule(UserLayerStore);
