<template>
  <v-btn elevation="1" @click="onClicked" color="gc-button gct-button--text" :disabled="disabled">
    <slot></slot>
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SimpleButton extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  onClicked() {
    this.$emit("click");
  }
}
</script>

<style lang="scss" scoped></style>
