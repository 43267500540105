import firebase from "firebase/compat/app";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { CustomLoginCallOutput } from "@/shared/call/CustomLoginCallData";

@Module({ dynamic: true, store, name: "authStore" })
class AuthStore extends VuexModule {
  // firebase authを最初にロードしたかどうか
  isLoadedAuth_as2 = false;

  // customLoginする際にその処理が終わっているかどうか
  initedCustomLogin_as2: Promise<CustomLoginCallOutput | null> | null = null;

  currentAuth_as2: firebase.User | null = null;

  @Action({ rawError: true })
  destroy_as2() {
    this.setCurrentAuth_as2({ currentAuth: null });
  }

  @Mutation
  setCurrentAuth_as2(p: { currentAuth: firebase.User | null }): void {
    this.currentAuth_as2 = p.currentAuth;
  }

  @Mutation
  loadedAuth_as2() {
    this.isLoadedAuth_as2 = true;
  }

  @Mutation
  setInitedCustomLogin_as2(p: { initedCustomLogin: Promise<CustomLoginCallOutput | null> | null }) {
    this.initedCustomLogin_as2 = p.initedCustomLogin;
  }
}

export const authStore = getModule(AuthStore);
