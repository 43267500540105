import { UserLayerModel } from "@/shared/model/UserLayerModel";
import { UserModel } from "@/shared/model/UserModel";
import { FirestoreModel } from "../FirestoreModel";
import * as util from "@/common/util";
import firebase from "firebase/compat/app";

export class UserData implements UserModel, FirestoreModel {
  // model
  id: string;
  userCode: string;
  groupId: string;
  defaultName: string;
  defaultColor: number[];
  imageUri: string;
  enabled: boolean;
  isSupport: boolean;
  updatedAt: firebase.firestore.Timestamp;
  registTags: Array<object>;
  idPartner: string | string[];
  isPartner: boolean;
  autoMessage?: string | undefined;

  // 独自
  isSelected: boolean;
  chatId: string;
  userLayer: UserLayerModel | null = null;

  constructor(id: string, selfId: string, model: UserModel) {
    this.id = id;
    this.isSelected = false;
    this.chatId = util.getIndividualChatId(id, selfId);

    this.userCode = model.userCode;
    this.groupId = model.groupId;
    this.defaultName = model.defaultName;
    this.defaultColor = model.defaultColor;
    this.imageUri = model.imageUri;
    this.enabled = model.enabled;
    this.updatedAt = model.updatedAt;
    this.isSupport = model.isSupport;
    this.registTags = model.registTags ?? [];
    this.idPartner = model.idPartner ?? "";
    this.isPartner = model.isPartner;
    this.autoMessage = model.autoMessage ?? "";
  }

  getDisplayName() {
    if (this.userLayer?.displayName) {
      return this.userLayer.displayName;
    }

    return this.defaultName;
  }

  getDefaultColorString() {
    return util.getHslColor(this.defaultColor);
  }

  getDefaultTextColorString() {
    return util.getHslTextColor(this.defaultColor);
  }

  getSetTagsArray() {
    if (this.userLayer?.setTags) {
      const setTags = this.userLayer.setTags.map((x) => {
        return x as { color: string; rubi: string; dispName: string };
      });
      return setTags;
    }

    return null;
  }
}
