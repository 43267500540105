import { userStore } from "@/store/UserStore";
import { chatStore } from "@/store/ChatStore";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { db, firebase } from "@/common/firebase";
import { ChatLayerModel } from "@/shared/model/ChatLayerModel";

interface ChatLayers {
  [id: string]: ChatLayerModel;
}

@Module({ dynamic: true, store, name: "chatLayerStore" })
class ChatLayerStore extends VuexModule {
  chatLayers_cls: ChatLayers = {};
  snapshotUnsubscriber_cls: (() => void) | null = null;

  @Action({ rawError: true })
  async initChatLayers_cls(): Promise<void> {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_cls) return;
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません chatStore");

    const selfId = userStore.currentUser_us.id;

    if (!selfId) return;

    try {
      // 初回取得
      const baseQuery = db.collection(`layers/${selfId}/chatLayers`);

      let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
      const snapshots = (await baseQuery.get()).docs;
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const chatLayer = snapshot.data() as ChatLayerModel;
        const chatId = snapshot.id;

        self.setChatLayer_cls({ chatId, chatLayer });
        chatStore.adjustChatLayer_cs({ chatId, chatLayer });

        if (maxUpdatedAt === null || chatLayer.updatedAt > maxUpdatedAt) {
          maxUpdatedAt = chatLayer.updatedAt;
        }
      }

      // 変更監視
      const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

      self.setSnapshotUnsubscriber_cls({
        snapshotUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (x) {
            const chatLayer = x.doc.data() as ChatLayerModel;
            const chatId = x.doc.id;

            if (x.type === "added") {
              self.setChatLayer_cls({ chatId, chatLayer });
              chatStore.adjustChatLayer_cs({ chatId, chatLayer });
            } else if (x.type === "modified") {
              self.setChatLayer_cls({ chatId, chatLayer });
              chatStore.adjustChatLayer_cs({ chatId, chatLayer });
            } else if (x.type === "removed") {
              self.unsetChatLayer_cls({ chatId });
              chatStore.adjustChatLayer_cs({ chatId, chatLayer: null });
            }
          });
        }),
      });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  @Action({ rawError: true })
  destroy_cls() {
    if (this.snapshotUnsubscriber_cls) {
      this.snapshotUnsubscriber_cls();
    }

    this.setSnapshotUnsubscriber_cls({ snapshotUnsubscriber: null });
    this.setChatLayers_cls({ chatLayers: {} });
  }

  @Mutation
  private setChatLayers_cls(p: { chatLayers: ChatLayers }) {
    this.chatLayers_cls = p.chatLayers;
  }

  @Mutation
  private setChatLayer_cls(p: { chatId: string; chatLayer: ChatLayerModel }) {
    this.chatLayers_cls[p.chatId] = p.chatLayer;
  }

  @Mutation
  private unsetChatLayer_cls(p: { chatId: string }) {
    delete this.chatLayers_cls[p.chatId];
  }

  @Mutation
  private setSnapshotUnsubscriber_cls(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_cls = p.snapshotUnsubscriber;
  }
}

export const chatLayerStore = getModule(ChatLayerStore);
