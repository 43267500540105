import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { userStore } from "@/store/UserStore";
import { ChatMessageData } from "@/common/data/ChatMessageData";
import { ChatMessageModel } from "@/shared/model/ChatMessageModel";
import { ChatType } from "@/shared/model/ChatModel";
import { ReadSignModel } from "@/shared/model/ReadSignModel";
import * as logic from "@/common/logic";
import * as util from "@/common/util";
import { FQ, FT } from "@/common/firebaseTypes";
import {
  OldestUnreadMessage,
  ReadAllMessageCallInput,
  ReadAllMessageCallOutput,
} from "@/shared/call/ReadAllMessageCallData";
import { firebase } from "@/common/firebase";

// 数字について根拠はないが多すぎず、少なすぎず
const chatCacheLimitCount_cms = 12;

// 同じく根拠がないが4時間はキャッシュを保持する
const chatCacheLimitSeconds_cms = 14400;

// 初回メッセージ取得件数
const messageInitialLimit = 20;

export interface CachedChatDataTable {
  [chatId: string]: {
    // メッセージそのもの
    chatMessages: ChatMessageDataTable;

    // 既読サイン(firestoreの構造に合わせてここに格納するが、実際に使用されるのは各ChatMessageDataのreadSign)
    readSigns: ReadSignTable;

    // 過去に遡ってすべてのメッセージを取得したかどうか
    isAllMessageGot: boolean;

    // 最も古い未読のchatMessageId(「ここから未読」のID)
    oldestUnreadMessageId: string;

    // 実際に画面表示されているかどうか(mobile表示の制御用)
    isActivated: boolean;

    // メッセージと既読サインの変更監視
    messageUnsubscriber: (() => void) | null;
    readSignUnsubscriber: (() => void) | null;

    // キャッシュ廃棄用setTimeoutのID
    cacheTimer: number | null;
  };
}

export interface ChatMessageDataTable {
  [chatMessageId: string]: ChatMessageData;
}

export interface ReadSignTable {
  [chatMessageId: string]: ReadSignModel;
}

@Module({ dynamic: true, store, name: "chatMessageStore" })
class ChatMessageStore extends VuexModule {
  cachedChats_cms: CachedChatDataTable = {};

  // ちらつき防止の苦肉の策
  isLoading_cs = false;

  // チャットIDの新旧を管理する(古いものから順にキャッシュ削除したいので)
  cachedChatIds_cms: string[] = [];

  // 初回取得は画面描画を最小限に抑えたいので一括反映する
  chatMessageInited_cms: ((chatId: string, chatMessages: ChatMessageDataTable) => void) | null = null;
  chatMessageUpserted_cms: ((chatId: string, chatMessage: ChatMessageData) => void) | null = null;
  chatMessageDeleted_cms: ((chatId: string, chatMessage: ChatMessageData) => void) | null = null;

  /**
   * チャットメッセージ初期化
   * @param p
   * @returns 未読メッセージが見つかればtrue, そうでなければfalse
   */
  @Action({ rawError: true })
  async initChatMessages_cms(p: { chatId: string; chatType: ChatType }): Promise<boolean> {
    // キャッシュに存在するかどうか確認。あればfirestoreから取得しない
    if (this.cachedChats_cms[p.chatId]) {
      // 未読メッセージがあるなら最も古いものを返却する
      const unreadMessages = Object.values(this.cachedChats_cms[p.chatId].chatMessages).filter((x) => x.isUnread());
      const foundUnreadMessage = unreadMessages.length > 0;

      if (foundUnreadMessage) {
        const oldestUnreadMessage = unreadMessages.reduce((a, c) => {
          if (a.createdAt < c.createdAt) {
            return a;
          } else {
            return c;
          }
        });

        if (oldestUnreadMessage) {
          this.setOldestUnreadMessageId_cms({
            chatId: p.chatId,
            oldestUnreadMessageId: oldestUnreadMessage.id,
          });
        }
      }

      if (this.chatMessageInited_cms) {
        this.chatMessageInited_cms(p.chatId, this.cachedChats_cms[p.chatId].chatMessages);
      }

      // チャットIDの新旧管理を更新(最新に)
      this.unsetCachedChatId_cms({ chatId: p.chatId });
      this.setCachedChatId_cms({ chatId: p.chatId });

      return foundUnreadMessage;
    }

    // キャッシュになければfirestoreから取得
    try {
      // Early Return
      if (this.cachedChats_cms[p.chatId]?.messageUnsubscriber) return false;
      if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: chatMessageStore");
      const selfId = userStore.currentUser_us.id;

      // 初回読み込み後は全てのメッセージを既読にする
      const oldestUnreadMessage = await this.callReadAllMessage({ chatId: p.chatId });

      // チャット初期化
      this.setInitCachedChat_cms({ chatId: p.chatId });
      this.limitCache_cms();

      let maxUnreadMessageUpdatedAt;
      let maxUnreadReadSignUpdatedAt;

      const oldestUnreadMessageCreatedAt = oldestUnreadMessage
        ? new firebase.firestore.Timestamp(
            oldestUnreadMessage.messageCreatedAt.seconds,
            oldestUnreadMessage.messageCreatedAt.nanoseconds
          )
        : undefined;

      if (oldestUnreadMessage) {
        [maxUnreadMessageUpdatedAt, maxUnreadReadSignUpdatedAt] = await this.getChatMessages_cms({
          chatId: p.chatId,
          chatType: p.chatType,
          selfId,
          begin: oldestUnreadMessageCreatedAt,
        });
      }

      let [maxMessageUpdatedAt, maxReadSignUpdatedAt] = await this.getChatMessages_cms({
        chatId: p.chatId,
        chatType: p.chatType,
        selfId,
        end: oldestUnreadMessageCreatedAt,
      });

      if (maxUnreadMessageUpdatedAt && maxMessageUpdatedAt && maxUnreadMessageUpdatedAt > maxMessageUpdatedAt) {
        maxMessageUpdatedAt = maxUnreadMessageUpdatedAt;
      }

      if (maxUnreadReadSignUpdatedAt && maxReadSignUpdatedAt && maxUnreadReadSignUpdatedAt > maxReadSignUpdatedAt) {
        maxReadSignUpdatedAt = maxUnreadReadSignUpdatedAt;
      }

      if (oldestUnreadMessage) {
        this.setOldestUnreadMessageId_cms({
          chatId: p.chatId,
          oldestUnreadMessageId: oldestUnreadMessage.chatMessageId,
        });
      }

      // 一括画面反映
      if (this.chatMessageInited_cms) {
        this.chatMessageInited_cms(p.chatId, this.cachedChats_cms[p.chatId].chatMessages);
      }

      this.observeChatMessages_cms({
        maxMessageUpdatedAt,
        maxReadSignUpdatedAt,
        chatId: p.chatId,
        chatType: p.chatType,
        selfId,
      });

      return oldestUnreadMessage !== undefined;
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  // 未読メッセージをすべて既読にする
  @Action({ rawError: true })
  async callReadAllMessage(p: { chatId: string }): Promise<OldestUnreadMessage | undefined> {
    // console.log("callReadAllMessage");

    const readAllMessageResult = await util.firebaseCall<ReadAllMessageCallInput, ReadAllMessageCallOutput>(
      "readAllMessageCall",
      {
        chatId: p.chatId,
      }
    );

    return readAllMessageResult.oldestUnreadMessage;
  }

  @Action({ rawError: true })
  private async getChatMessages_cms(p: {
    chatId: string;
    chatType: ChatType;
    selfId: string;
    begin?: FT;
    end?: FT;
  }): Promise<[FT | null, FT | null]> {
    const self = this;
    let messageLimit: number | null = null;
    let baseMessageQuery: FQ = logic.getBaseMessageQuery(p.chatId);

    if (p.begin) {
      baseMessageQuery = baseMessageQuery.where("createdAt", ">=", p.begin);
    }

    if (p.end) {
      // "<" ではなく "<=" としているのは万が一ちょうど境目に同時刻のメッセージがあるかもしれないので
      baseMessageQuery = baseMessageQuery.where("createdAt", "<=", p.end);
    }

    // 開始時刻指定がないなら取得数に上限をもうける
    if (!p.begin) {
      if (p.end) {
        // とはいえほぼ重なることはないはずなので上記の分1件多めにとってくる
        // 厳密には同時刻の書き込みがこの限界件数分以上に重なった場合は無限ループになったりなど
        // おかしな現象がおきるが起きえないと考える
        messageLimit = messageInitialLimit + 1;
      } else {
        messageLimit = messageInitialLimit;
      }
    }

    baseMessageQuery = baseMessageQuery.where("enabled", "==", true).orderBy("createdAt", "desc");
    const initialMessageQuery = messageLimit ? baseMessageQuery.limit(messageLimit) : baseMessageQuery;

    // メッセージ初回取得
    let minCreatedAt: FT | null = null;
    let maxCreatedAt: FT | null = null;
    let maxMessageUpdatedAt: FT | null = null;
    const messageSnapshots = (await initialMessageQuery.get()).docs;

    // ループを逆順に回しているのは予めソートされた状態にしておき比較回数を減らしたいという考えのため
    for (let i = messageSnapshots.length - 1; i >= 0; i--) {
      const snapshot = messageSnapshots[i];
      const id = snapshot.id;
      const chatMessageModel = snapshot.data() as ChatMessageModel;

      // isCancel対応（メッセージは表示しない）
      if (chatMessageModel.isCancel && chatMessageModel.isCancel === true) {
        continue;
      }

      self.setChatMessage_cms({
        chatId: p.chatId,
        chatMessageData: logic.getChatMessageData(p.chatId, p.chatType, id, p.selfId, chatMessageModel),
      });

      if (minCreatedAt === null || chatMessageModel.createdAt < minCreatedAt) {
        minCreatedAt = chatMessageModel.createdAt;
      }

      if (maxCreatedAt === null || chatMessageModel.createdAt > maxCreatedAt) {
        maxCreatedAt = chatMessageModel.createdAt;
      }

      if (maxMessageUpdatedAt === null || chatMessageModel.updatedAt > maxMessageUpdatedAt) {
        maxMessageUpdatedAt = chatMessageModel.updatedAt;
      }
    }

    // 限界未満の取得なら最後まで取得したと判断
    const isAllMessageGot = messageLimit ? messageSnapshots.length < messageLimit : true;
    self.setIsAllMessageGot_cms({ chatId: p.chatId, isAllMessageGot });

    // 起きえないとは思うが将来一括書き込み機能みたいなのを実装したときに起きるかもしれないので
    // 念のため予防しておく（無限ループによる自滅だけは避けたいので）
    if (!isAllMessageGot && minCreatedAt && maxCreatedAt && minCreatedAt.isEqual(maxCreatedAt)) {
      throw new Error("同一時刻の書き込みが一括取得件数を超えています");
    }

    let maxReadSignUpdatedAt: FT | null = null;
    if (messageSnapshots.length) {
      // 既読サイン
      const baseReadSignQuery = logic.getBaseReadSignQuery(p.selfId, p.chatId);
      const initialReadSignQuery = baseReadSignQuery
        .where("messageCreatedAt", ">=", minCreatedAt)
        .where("messageCreatedAt", "<=", maxCreatedAt);

      // 初回取得
      const readSignSnapshots = (await initialReadSignQuery.get()).docs;

      for (let i = 0; i < readSignSnapshots.length; i++) {
        const readSignSnapshot = readSignSnapshots[i];
        const id = readSignSnapshot.id;
        const readSign = readSignSnapshot.data() as ReadSignModel;

        self.setReadSign_cms({ chatId: p.chatId, chatMessageId: id, readSign });

        // メッセージに適用
        const chatMessageData = self.cachedChats_cms[p.chatId].chatMessages[id];
        if (chatMessageData) {
          self.setReadSignToChatMessage_cms({ chatId: p.chatId, chatMessageId: id, readSign });
        }

        if (maxReadSignUpdatedAt === null || readSign.updatedAt > maxReadSignUpdatedAt) {
          maxReadSignUpdatedAt = readSign.updatedAt;
        }
      }
    }

    return [maxMessageUpdatedAt, maxReadSignUpdatedAt];
  }

  @Action({ rawError: true })
  async getPastChatMessages_cms(p: { chatId: string; chatType: ChatType; sectionTime: FT }): Promise<void> {
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: chatMessageStore");
    const selfId = userStore.currentUser_us.id;

    await this.getChatMessages_cms({
      chatId: p.chatId,
      chatType: p.chatType,
      selfId,
      end: p.sectionTime,
    });

    // 一括画面反映
    if (this.chatMessageInited_cms) {
      this.chatMessageInited_cms(p.chatId, this.cachedChats_cms[p.chatId].chatMessages);
    }
  }

  @Action({ rawError: true })
  observeChatMessages_cms(p: {
    maxMessageUpdatedAt: FT | null;
    maxReadSignUpdatedAt: FT | null;
    chatId: string;
    chatType: ChatType;
    selfId: string;
  }) {
    const self = this;

    // メッセージ変更監視
    const baseMessageQuery = logic.getBaseMessageQuery(p.chatId);
    const observeQuery = p.maxMessageUpdatedAt
      ? baseMessageQuery.where("updatedAt", ">", p.maxMessageUpdatedAt)
      : baseMessageQuery;

    self.setMessageUnsubscriber_cms({
      chatId: p.chatId,
      messageUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const id = x.doc.id;
          const chatMessageModel = x.doc.data() as ChatMessageModel;
          const readSign = self.cachedChats_cms[p.chatId].readSigns[id];

          const chatMessageData = logic.getChatMessageData(p.chatId, p.chatType, id, p.selfId, chatMessageModel);

          // isCancel対応（メッセージは表示しない）
          if (chatMessageModel.isCancel && chatMessageModel.isCancel === true) {
            self.deleteMessage_cms({ chatId: p.chatId, chatMessageData });
            return;
          }

          if (x.type === "added") {
            if (chatMessageData.enabled) {
              if (readSign) {
                chatMessageData.readSign = readSign;
              }

              self.upsertMessage_cms({ chatId: p.chatId, chatMessageData, tryMarkOledestUnreadMessage: true });
            } else {
              self.deleteMessage_cms({ chatId: p.chatId, chatMessageData });
            }
          } else if (x.type === "modified") {
            if (chatMessageData.enabled) {
              // TODO: 今のところ必要ないが、もしメッセージそのものに変更があるような場合は
              // 画面に存在しないものを追加してしまわないように
              // あれば更新、なければstoreのみに保存する(あるいは保存しなくてもいいかもしれない)
              // というふうにしたほうが厳密だ
              if (readSign) {
                chatMessageData.readSign = readSign;
              }

              self.upsertMessage_cms({ chatId: p.chatId, chatMessageData, tryMarkOledestUnreadMessage: true });
            } else {
              self.deleteMessage_cms({ chatId: p.chatId, chatMessageData });
            }
          } else if (x.type === "removed") {
            self.deleteMessage_cms({ chatId: p.chatId, chatMessageData });
          }
        });
      }),
    });

    // 既読サイン変更監視
    const baseReadSignQuery = logic.getBaseReadSignQuery(p.selfId, p.chatId);
    const observeReadSignQuery = p.maxReadSignUpdatedAt
      ? baseReadSignQuery.where("updatedAt", ">", p.maxReadSignUpdatedAt)
      : baseReadSignQuery;

    self.setReadSignUnsubscriber_cms({
      chatId: p.chatId,
      readSignUnsubscriber: observeReadSignQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const chatMessageId = x.doc.id;
          const readSign = x.doc.data() as ReadSignModel;
          const chatMessageData = self.cachedChats_cms[p.chatId].chatMessages[chatMessageId];

          if (x.type === "added") {
            self.setReadSign_cms({ chatId: p.chatId, chatMessageId, readSign });

            // 既読サインの追加があってもそれに対応するメッセージがあるとは限らないことに注意
            if (chatMessageData) {
              self.setReadSignToChatMessage_cms({ chatId: p.chatId, chatMessageId, readSign });
              self.upsertMessage_cms({ chatId: p.chatId, chatMessageData });
            }
          } else if (x.type === "modified") {
            self.setReadSign_cms({ chatId: p.chatId, chatMessageId, readSign });

            // 既読サインの追加があってもそれに対応するメッセージがあるとは限らないことに注意
            if (chatMessageData) {
              self.setReadSignToChatMessage_cms({ chatId: p.chatId, chatMessageId, readSign });
              self.upsertMessage_cms({ chatId: p.chatId, chatMessageData });
            }
          } else if (x.type === "removed") {
            self.unsetReadSign_cms({ chatId: p.chatId, chatMessageId });

            if (chatMessageData) {
              self.setReadSignToChatMessage_cms({ chatId: p.chatId, chatMessageId, readSign: null });
              self.deleteMessage_cms({ chatId: p.chatId, chatMessageData });
            }
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  upsertMessage_cms(p: { chatId: string; chatMessageData: ChatMessageData; tryMarkOledestUnreadMessage?: boolean }) {
    this.setChatMessage_cms({ chatId: p.chatId, chatMessageData: p.chatMessageData });

    if (p.tryMarkOledestUnreadMessage) {
      this.tryMarkOldestUnreadMessage_cms({ chatId: p.chatId, chatMessageData: p.chatMessageData });
    }

    if (this.chatMessageUpserted_cms) {
      this.chatMessageUpserted_cms(p.chatId, p.chatMessageData);
    }
  }

  @Action({ rawError: true })
  private deleteMessage_cms(p: { chatId: string; chatMessageData: ChatMessageData }) {
    this.unsetChatMessage_cms({ chatId: p.chatId, chatMessageData: p.chatMessageData });

    if (this.chatMessageDeleted_cms) {
      this.chatMessageDeleted_cms(p.chatId, p.chatMessageData);
    }
  }

  @Action({ rawError: true })
  private dispose_cms(p: { chatId: string }) {
    // console.log("disposing: " + p.chatId);

    if (!this.cachedChats_cms[p.chatId]) return;

    if (this.cachedChats_cms[p.chatId].messageUnsubscriber) {
      (this.cachedChats_cms[p.chatId].messageUnsubscriber as () => void)();
    }

    if (this.cachedChats_cms[p.chatId].readSignUnsubscriber) {
      (this.cachedChats_cms[p.chatId].readSignUnsubscriber as () => void)();
    }

    if (this.cachedChats_cms[p.chatId].cacheTimer) {
      window.clearTimeout(this.cachedChats_cms[p.chatId].cacheTimer as number);
    }

    this.unsetCachedChat_cms({ chatId: p.chatId });
    this.unsetCachedChatId_cms({ chatId: p.chatId });
  }

  @Action({ rawError: true })
  unsetOldestUnreadMessage_cms(p: { chatId: string }) {
    const oldestUnreadMessageId = this.cachedChats_cms[p.chatId]?.oldestUnreadMessageId;

    if (oldestUnreadMessageId) {
      this.unsetOldestUnreadMessageId_cms({ chatId: p.chatId });

      if (this.chatMessageUpserted_cms && this.cachedChats_cms[p.chatId]?.chatMessages[oldestUnreadMessageId]) {
        this.chatMessageUpserted_cms(p.chatId, this.cachedChats_cms[p.chatId].chatMessages[oldestUnreadMessageId]);
      }
    }
  }

  @Action({ rawError: true })
  activated_cms(p: { chatId: string }) {
    this.setIsActivated_cms({ chatId: p.chatId, isActivated: true });
  }

  @Action({ rawError: true })
  deactivated_cms(p: { chatId: string }) {
    this.setIsActivated_cms({ chatId: p.chatId, isActivated: false });
  }

  @Action({ rawError: true })
  tryMarkOldestUnreadMessage_cms(p: { chatId: string; chatMessageData: ChatMessageData }) {
    // メッセージ自体が未読じゃないなら何もしない
    if (!p.chatMessageData.isUnread()) return;

    // すでに「ここから未読」があるなら何もしない
    if (this.cachedChats_cms[p.chatId]?.oldestUnreadMessageId) return;

    // 背面に隠れていないなら何もしない
    if (this.cachedChats_cms[p.chatId]?.isActivated) return;

    this.setOldestUnreadMessageId_cms({ chatId: p.chatId, oldestUnreadMessageId: p.chatMessageData.id });
  }

  @Action({ rawError: true })
  destroy_cms(): void {
    const self = this;
    const keys = Object.keys(this.cachedChats_cms);
    keys.forEach((key) => {
      self.dispose_cms({ chatId: key });
    });

    this.setChatMessageInited_cms({ chatMessageInited: null });
    this.setChatMessageUpserted_cms({ chatMessageUpserted: null });
    this.setChatMessageDeleted_cms({ chatMessageDeleted: null });
    this.setIsLoading_cs({ isLoading: false });
  }

  @Action({ rawError: true })
  private limitCache_cms(): void {
    // 限界件数を超えたならキャッシュ削除
    if (this.cachedChatIds_cms.length > chatCacheLimitCount_cms) {
      this.dispose_cms({ chatId: this.cachedChatIds_cms[0] });
    }
  }

  @Action({ rawError: true })
  startCacheTimer(p: { chatId: string }) {
    const self = this;
    if (self.cachedChats_cms[p.chatId]) {
      self.cachedChats_cms[p.chatId].cacheTimer = window.setTimeout(() => {
        self.dispose_cms({ chatId: p.chatId });
      }, chatCacheLimitSeconds_cms * 1000);
    }
  }

  @Action({ rawError: true })
  endCacheTimer(p: { chatId: string }) {
    if (this.cachedChats_cms[p.chatId]?.cacheTimer) {
      window.clearTimeout(this.cachedChats_cms[p.chatId].cacheTimer as number);
    }
  }

  @Mutation
  private setInitCachedChat_cms(p: { chatId: string }) {
    this.cachedChats_cms[p.chatId] = {
      chatMessages: {},
      readSigns: {},
      isAllMessageGot: false,
      oldestUnreadMessageId: "",
      isActivated: true,
      messageUnsubscriber: null,
      readSignUnsubscriber: null,
      cacheTimer: null,
    };

    this.cachedChatIds_cms.push(p.chatId);
  }

  @Mutation
  private unsetCachedChat_cms(p: { chatId: string }) {
    delete this.cachedChats_cms[p.chatId];
  }

  @Mutation
  private setCachedChatId_cms(p: { chatId: string }) {
    this.cachedChatIds_cms.push(p.chatId);
  }

  @Mutation
  private unsetCachedChatId_cms(p: { chatId: string }) {
    util.removeElement(this.cachedChatIds_cms, p.chatId);
  }

  @Mutation
  private setChatMessage_cms(p: { chatId: string; chatMessageData: ChatMessageData }) {
    this.cachedChats_cms[p.chatId].chatMessages[p.chatMessageData.id] = p.chatMessageData;
  }

  @Mutation
  private unsetChatMessage_cms(p: { chatId: string; chatMessageData: ChatMessageData }) {
    delete this.cachedChats_cms[p.chatId].chatMessages[p.chatMessageData.id];
  }

  @Mutation
  private setReadSign_cms(p: { chatId: string; chatMessageId: string; readSign: ReadSignModel }) {
    this.cachedChats_cms[p.chatId].readSigns[p.chatMessageId] = p.readSign;
  }

  @Mutation
  private unsetReadSign_cms(p: { chatId: string; chatMessageId: string }) {
    delete this.cachedChats_cms[p.chatId].readSigns[p.chatMessageId];
  }

  @Mutation
  private setIsAllMessageGot_cms(p: { chatId: string; isAllMessageGot: boolean }) {
    if (this.cachedChats_cms[p.chatId]) {
      this.cachedChats_cms[p.chatId].isAllMessageGot = p.isAllMessageGot;
    }
  }

  @Mutation
  private setMessageUnsubscriber_cms(p: { chatId: string; messageUnsubscriber: (() => void) | null }) {
    if (this.cachedChats_cms[p.chatId]) {
      this.cachedChats_cms[p.chatId].messageUnsubscriber = p.messageUnsubscriber;
    }
  }

  @Mutation
  private setReadSignUnsubscriber_cms(p: { chatId: string; readSignUnsubscriber: (() => void) | null }) {
    if (this.cachedChats_cms[p.chatId]) {
      this.cachedChats_cms[p.chatId].readSignUnsubscriber = p.readSignUnsubscriber;
    }
  }

  @Mutation
  private setReadSignToChatMessage_cms(p: { chatId: string; chatMessageId: string; readSign: ReadSignModel | null }) {
    this.cachedChats_cms[p.chatId].chatMessages[p.chatMessageId].readSign = p.readSign;
  }

  @Mutation
  private setOldestUnreadMessageId_cms(p: { chatId: string; oldestUnreadMessageId: string }) {
    if (this.cachedChats_cms[p.chatId]?.chatMessages[p.oldestUnreadMessageId]) {
      // チャットにも記録しておく(解除用)
      this.cachedChats_cms[p.chatId].oldestUnreadMessageId = p.oldestUnreadMessageId;
      this.cachedChats_cms[p.chatId].chatMessages[p.oldestUnreadMessageId].isOldestUnread = true;
    }
  }

  @Mutation
  private setIsActivated_cms(p: { chatId: string; isActivated: boolean }) {
    if (this.cachedChats_cms[p.chatId]) {
      this.cachedChats_cms[p.chatId].isActivated = p.isActivated;
    }
  }

  @Mutation
  private unsetOldestUnreadMessageId_cms(p: { chatId: string }) {
    if (this.cachedChats_cms[p.chatId] && this.cachedChats_cms[p.chatId].oldestUnreadMessageId) {
      const oldestUnreadMessageId = this.cachedChats_cms[p.chatId].oldestUnreadMessageId;

      this.cachedChats_cms[p.chatId].oldestUnreadMessageId = "";
      if (this.cachedChats_cms[p.chatId].chatMessages[oldestUnreadMessageId]) {
        this.cachedChats_cms[p.chatId].chatMessages[oldestUnreadMessageId].isOldestUnread = false;
      }
    }
  }

  @Mutation
  setChatMessageInited_cms(p: {
    chatMessageInited: ((chatId: string, chatMessages: ChatMessageDataTable) => void) | null;
  }) {
    this.chatMessageInited_cms = p.chatMessageInited;
  }

  @Mutation
  setChatMessageUpserted_cms(p: {
    chatMessageUpserted: ((chatId: string, chatMessage: ChatMessageData) => void) | null;
  }) {
    this.chatMessageUpserted_cms = p.chatMessageUpserted;
  }

  @Mutation
  setChatMessageDeleted_cms(p: {
    chatMessageDeleted: ((chatId: string, chatMessage: ChatMessageData) => void) | null;
  }) {
    this.chatMessageDeleted_cms = p.chatMessageDeleted;
  }

  @Mutation
  setIsLoading_cs(p: { isLoading: boolean }) {
    this.isLoading_cs = p.isLoading;
  }
}

export const chatMessageStore = getModule(ChatMessageStore);
