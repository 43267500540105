// 汎用的に使うようなutil
import dayjs from "dayjs";
import { firebase, functions } from "@/common/firebase";
import { appStore } from "@/store/AppStore";
import { DialogOption } from "@/common/DialogOption";
import * as sharedUtil from "@/shared/util";
import { ImageSrc } from "./data/UploadFile";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
const moji = require("moji") as any;
import { UserData } from "@/common/data/UserData";

export function now() {
  return firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
}

export async function firebaseCall<Input, Output>(functionName: string, functionParameter: Input): Promise<Output> {
  const callableFunction = functions.httpsCallable(functionName);

  const result = await callableFunction(functionParameter);
  return result.data as Output;
}

export function showDialog(dialogOption: DialogOption): Promise<boolean> {
  return appStore.startDialog_as1(dialogOption);
}

export function showProgressOverray(): void {
  return appStore.showProgressOverray_as1();
}

export function hideProgressOverray(): void {
  return appStore.hideProgressOverray_as1();
}

export function getCurrentDate(): Date {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function removeElement(array: any[], element: any): boolean {
  let i: number;
  let found = false;
  for (i = 0; i < array.length; i++) {
    if (array[i] === element) {
      found = true;
      break;
    }
  }

  array.splice(i, 1);
  return found;
}

// basisは予めtruncateされていることが前提
export function getDateHourString(source: Date, basis: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  const basisMoment = dayjs(basis);
  const sourceMomentTruncated = sourceMoment.startOf("day");
  const diffDays = basisMoment.diff(sourceMomentTruncated, "day");

  if (diffDays === 0) {
    return sourceMoment.format("H:mm");
  }

  if (diffDays === 1) {
    return sourceMoment.format("昨日 H:mm");
  }

  if (diffDays < 7) {
    return sourceMoment.format("dddd H:mm");
  }

  const diffYears = basisMoment.diff(sourceMomentTruncated, "year");

  if (diffYears === 0) {
    return dayjs(source).format("M月D日 H:mm");
  }

  return dayjs(source).format("YYYY年M月D日 H:mm");
}

// basisは予めtruncateされていることが前提
export function getDateMonthString(source: Date, basis: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  const basisMoment = dayjs(basis);
  const sourceMomentTruncated = sourceMoment.startOf("day");
  const diffYears = basisMoment.diff(sourceMomentTruncated, "year");

  if (diffYears === 0) {
    return dayjs(source).format("M月D日 H:mm");
  }

  return dayjs(source).format("YYYY年M月D日 H:mm");
}

// basisは予めtruncateされていることが前提
export function getDateString(source: Date, basis: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  const basisMoment = dayjs(basis);
  const sourceMomentTruncated = sourceMoment.startOf("day");
  const diffDays = basisMoment.diff(sourceMomentTruncated, "day");

  if (diffDays === 0) {
    return sourceMoment.format("H:mm");
  }

  if (diffDays === 1) {
    return sourceMoment.format("昨日");
  }

  if (diffDays < 7) {
    return sourceMoment.format("dddd");
  }

  return sourceMoment.format("M月D日");
}

export function getDateYmdString(source: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  return sourceMoment.format("YYYY/M/D HH:mm");
}

export function getDateYmdOnlyString(source: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  return sourceMoment.format("YYYY/M/D");
}

export function getDateYmd8digitString(source: Date): string {
  if (!source) {
    return "";
  }

  const sourceMoment = dayjs(source);
  return sourceMoment.format("YYYY/MM/DD");
}

export const getUnreadCountString = (unreadCount: number): string => {
  if (unreadCount >= 100) {
    return "99+";
  }

  if (unreadCount >= 1) {
    return unreadCount.toString();
  }

  return "";
};

export const getDocId = (source: string): string => {
  return sharedUtil.getDocId(source);
};

export const getIndividualChatId = (oneId: string, otherId: string): string => {
  return sharedUtil.getIndividualChatId(oneId, otherId);
};

export const getOpponentIdFromChatId = (chatId: string, selfId: string): string => {
  return sharedUtil.getOpponentIdFromChatId(chatId, selfId);
};

// ファイルが画像かどうか(もっと厳密に判定すべきかもしれない)
export const isImage = (imageFile: File) => {
  if (imageFile.type == "image/tiff" || imageFile.type == "image/svg+xml") {
    return false;
  } else {
    return imageFile.type.startsWith("image");
  }
};

// ファイルからイメージソース取得
export const getImageSrc = (imageFile: File) => {
  return new Promise<ImageSrc>((resolve) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      resolve(e.target?.result || "");
    };

    reader.readAsDataURL(imageFile);
  });
};

export const getImageFromSrc = (imageSrc: ImageSrc) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };

    img.src = imageSrc as string;
  });
};

export const getFileUrlFromSrc = (url: string) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };

    img.src = url;
  });
};

export function isEmpty(obj: any): boolean {
  return !Object.keys(obj).length;
}

export function isNotEmpty(obj: any): boolean {
  return !isEmpty(obj);
}

export function getShortSizeString(size: number): string {
  // ファイルサイズの計算の基準、Windows:1024、Mac:1000
  // どちらに合わせるべきだろうか？とりあえずユーザーの多いWindowsに合わせる
  const c = 1024;

  if (size < c) {
    return size + "B";
  } else if (size < c * c) {
    return (size / c).toFixed(0) + "KB";
  } else {
    return (size / c / c).toFixed(1) + "MB";
  }

  return "";
}

export function getHslColor(defaultColor: number[]): string {
  if (defaultColor.length > 2) {
    return `hsl(${defaultColor[0]}, ${defaultColor[1]}%, ${defaultColor[2]}%)`;
  }

  return "";
}

export function getHslTextColor(defaultColor: number[]): string {
  if (defaultColor.length > 2) {
    return `hsl(${defaultColor[0]}, ${defaultColor[1] - 45}%, ${defaultColor[2] - 50}%)`;
  }

  return "";
}

// web -> native event
type WebEvent =
  | "UpdateUIColor"
  | "Loggedin"
  | "AppInited"
  | "LoginViewAppearing"
  | "TotalUnreadCountUpdated"
  | "SendVersionInfo"
  | "RequestAccurateHeight";

export function postMessageToNative(eventType: WebEvent, param?: string) {
  window.webkit?.messageHandlers[eventType]?.postMessage(param);
}

// デバッグなどに
// export async function wait(millisec: number) {
//   await new Promise(resolve => setTimeout(resolve, millisec));
// }

// HSL色空間からRGB色空間へ変換する
//  h(hue)       : 色相/色合い       0-360度の値
//  s(saturation): 彩度/鮮やかさ     0-100%の値
//  l(lightness) : 明度/明るさ       0-100%の値
export const convertHslToRgbTextColor = (h: number, s: number, l: number): string => {
  let max: number, min: number;
  const rgb = { r: 0, g: 0, b: 0 };

  if (h == 360) {
    h = 0;
  }

  if (l <= 49) {
    max = 2.55 * (l + l * (s / 100));
    min = 2.55 * (l - l * (s / 100));
  } else {
    max = 2.55 * (l + (100 - l) * (s / 100));
    min = 2.55 * (l - (100 - l) * (s / 100));
  }

  if (h < 60) {
    rgb.r = max;
    rgb.g = min + (max - min) * (h / 60);
    rgb.b = min;
  } else if (h >= 60 && h < 120) {
    rgb.r = min + (max - min) * ((120 - h) / 60);
    rgb.g = max;
    rgb.b = min;
  } else if (h >= 120 && h < 180) {
    rgb.r = min;
    rgb.g = max;
    rgb.b = min + (max - min) * ((h - 120) / 60);
  } else if (h >= 180 && h < 240) {
    rgb.r = min;
    rgb.g = min + (max - min) * ((240 - h) / 60);
    rgb.b = max;
  } else if (h >= 240 && h < 300) {
    rgb.r = min + (max - min) * ((h - 240) / 60);
    rgb.g = min;
    rgb.b = max;
  } else if (h >= 300 && h < 360) {
    rgb.r = max;
    rgb.g = min;
    rgb.b = min + (max - min) * ((360 - h) / 60);
  }
  return `rgb(${Math.round(rgb.r)}, ${Math.round(rgb.g)}, ${Math.round(rgb.b)})`;
};

export function getSelectUserIdFromChatId(chatId: string, selfId: string) {
  const ids = chatId.match(/^([^_]*)_(.*)$/);
  if (ids) {
    const rep = ids[2].replace(selfId, "");
    return rep.replace(/-/, "");
  }
  return "";
}

export function isSameDay(one: Date, other: Date): boolean {
  // 説明しやすいように日本時刻扱い
  const oneDayTruncated = dayjs(one).tz("Asia/Tokyo").startOf("day");
  const otherDayTruncated = dayjs(other).tz("Asia/Tokyo").startOf("day");

  return oneDayTruncated.isSame(otherDayTruncated);
}

export function daysLater(daysAfter: number) {
  const date = dayjs();
  const later = date.add(daysAfter, "day");
  return later.toDate();
}

export function getDateYmdFromString(dateStr: string) {
  if (!dateStr) return "";

  const [year, month, day] = dateStr.split("/");
  if (!year || !month || !day) return "";

  const regex = new RegExp(/^[0-9]+/);
  if (!regex.test(year) || !regex.test(month) || !regex.test(day) || year.length > 4) return "";

  const y = parseInt(year);
  const m = parseInt(month);
  const d = parseInt(day);
  return new Date(y, m - 1, d);
}

export function convertToHalfLowercase(str: string) {
  if (!str) return "";
  return moji(str)
    .convert("ZE", "HE")
    .convert("ZS", "HS")
    .convert("HG", "KK")
    .convert("ZK", "HK")
    .toString()
    .toLowerCase();
}

export function getSetDateAndTime(date: string, time: string) {
  const datetime = new Date(`${date} ${time}`);
  if (isNaN(datetime.getDate())) {
    return new Date();
  }
  return datetime;
}

export function sortUser(a: UserData, b: UserData): number {
  const aName = a.getDisplayName();
  const bName = b.getDisplayName();
  if (aName < bName) {
    return -1;
  } else if (aName === bName) {
    return 0;
  } else {
    return 1;
  }
}

export const getLoginId = (): string => {
  return localStorage.getItem("loginId") ?? "";
};
