// import { messaging } from "./../../functions/src/common/configure";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/messaging";

// VUEの環境変数の取り扱いに関しては以下を参考
// https://cli.vuejs.org/guide/mode-and-env.html#modes
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
const db = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.app().functions("asia-northeast1");
let messaging: firebase.messaging.Messaging;

if (
  "serviceWorker" in navigator &&
  "PushManager" in window &&
  window.greenchat.disableNotification !== "true" &&
  !window.greenchat.nativeType
) {
  // これを読み込むだけで勝手にfirebase-messaging-sw.jsを読み込んで
  // ブラウザ(brave-browser, electronなど)によっては勝手に自爆(AbortError)するので
  // このrequireは絶対if文の外に出さないこと
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
}

let functionOrigin: string;

// デバッグ設定(通知機能などの試験をするためlocalhostに設定、モバイル端末試験はdev環境にデプロイするなど)
if (process.env.VUE_APP_BUILD_MODE === "local_dev") {
  const local = process.env.VUE_APP_HOST as string;

  // functions
  functionOrigin = "http://" + local + ":5001/pds-connect-dev/asia-northeast1/";

  // firestore
  db.useEmulator(local, 8000);

  // functions
  functions.useEmulator(local, 5001);

  // storage
  storage.useEmulator(local, 9199);

  // ios12.x以前の mobile safari では auth emulator はなぜかエラーになってしまうので
  // 動作確認したい場合は適宜コメントアウト(本番環境のauthを使うことに注意)
  // またはstorageを使う場合もオフにしないとアップロードできない(storageはエミュレーターがない、2021-01-19時点)
  // auth.useEmulator("http://" + local + ":9099");
} else {
  functionOrigin = process.env.VUE_APP_FUNCTION_ORIGIN as string;
}

const vapidKey = process.env.VUE_APP_VAPID_KEY;

export { firebase, auth, db, functions, storage, messaging, functionOrigin, vapidKey };
