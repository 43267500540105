import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { UserData } from "@/common/data/UserData";
import { UserLayerModel } from "@/shared/model/UserLayerModel";
import { db, firebase } from "@/common/firebase";
import { UserModel } from "@/shared/model/UserModel";
import { UserPrivateData } from "@/common/data/UserPrivateData";
import * as util from "@/common/util";
import * as logic from "@/common/logic";

export interface UserDataTable {
  [id: string]: UserData;
}

// ここで取得したUserはマスタでもある
@Module({ dynamic: true, store, name: "userStore" })
class UserStore extends VuexModule {
  currentUser_us: UserData | null = null;
  currentUserPrivate_us: UserPrivateData | null = null;

  groupUsers_us: UserDataTable = {};
  groupUserInited_us: ((userDataTable: UserDataTable) => void) | null = null;
  groupUserUpserted_us: ((userData: UserData) => void) | null = null;
  groupUserDeleted_us: ((userData: UserData) => void) | null = null;
  groupUserUnsubscriber_us: (() => void) | null = null;
  groupOtherUserUnsubscriber_us: (() => void) | null = null;

  // support
  supportUsers_us: UserDataTable = {};
  supportUserInited_us: ((userDataTable: UserDataTable) => void) | null = null;
  supportUserUpserted_us: ((userData: UserData) => void) | null = null;
  supportUserDeleted_us: ((userData: UserData) => void) | null = null;
  supportUserUnsubscriber_us: (() => void) | null = null;

  // oroshi
  oroshiUsers_us: UserDataTable = {};
  oroshiUserInited_us: ((userDataTable: UserDataTable) => void) | null = null;
  oroshiUserUpserted_us: ((userData: UserData) => void) | null = null;
  oroshiUserDeleted_us: ((userData: UserData) => void) | null = null;
  oroshiUserUnsubscriber_us: (() => void) | null = null;

  // 非同期なのでActionなことに注意
  @Action({ rawError: true })
  async initUsers_us(): Promise<void> {
    const self = this;

    // Early Return
    if (self.groupUserUnsubscriber_us) return;
    if (!self.currentUser_us) throw new Error("ユーザーデータがありません: userStore");

    const selfId = self.currentUser_us.id;

    try {
      await this.getSupportUsers({ selfId });
      await this.getOroshiUsers({ selfId, idPartner: self.currentUser_us.idPartner });
      await this.getGroupUsers({ selfId, currentUser: self.currentUser_us });
      await this.getGroupOtherUsers({ selfId, currentUser: self.currentUser_us });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  @Action({ rawError: true })
  private async getGroupUsers(p: { selfId: string; currentUser: UserData }): Promise<void> {
    const self = this;

    // グループIDがないなら戻る
    if (!p.currentUser.groupId) return;

    // 初回取得(サポートユーザーはすべてのユーザーをグループユーザーだと考える)
    let baseQuery;
    if (p.currentUser.isSupport) {
      baseQuery = db.collection("users");
    } else {
      baseQuery = db.collection("users").where("groupId", "==", p.currentUser.groupId);
    }

    let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
    const snapshots = (await baseQuery.get()).docs;
    for (let i = 0; i < snapshots.length; i++) {
      const snapshot = snapshots[i];
      const userModel = snapshot.data() as UserModel;
      const id = snapshot.id;

      // Queryの"!="で除外してしまうと下記のupdatedAtと合わせて異なるフィールドに
      // 範囲条件を二つ設定することになり、firestoreの制限に引っかかるのでロジックで除外する
      // 監視の方にも同じロジックがあるので注意
      if (p.selfId === id || userModel.isSupport) continue;

      // 卸グループ に存在するユーザーはグループユーザーに含めない
      if (self.oroshiUsers_us[id]) continue;

      const groupUser = logic.getUserData(id, p.selfId, userModel);

      self.setGroupUser_us({ groupUser });

      if (maxUpdatedAt === null || userModel.updatedAt > maxUpdatedAt) {
        maxUpdatedAt = userModel.updatedAt;
      }
    }

    // 一括画面反映
    if (self.groupUserInited_us) {
      self.groupUserInited_us(self.groupUsers_us);
    }

    // 変更監視
    const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

    self.setGroupUserUnsubscriber_us({
      groupUserUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const userModel = x.doc.data() as UserModel;
          const id = x.doc.id;

          // 同上
          if (p.selfId === id || userModel.isSupport) return;

          if (self.oroshiUsers_us[id]) return;

          const groupUser = logic.getUserData(id, p.selfId, userModel);

          if (x.type === "added") {
            self.upsertGroupUser_us({ groupUser });
          } else if (x.type === "modified") {
            self.upsertGroupUser_us({ groupUser });
          } else if (x.type === "removed") {
            self.deleteGroupUser_us({ groupUser });
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  private async getGroupOtherUsers(p: { selfId: string; currentUser: UserData }): Promise<void> {
    const self = this;

    // ログインユーザーがパートナー親（isPartner=true）の場合、パートナーID（idPartner）にselfIdを持っているユーザーを追加する
    if (!p.currentUser.isPartner) return;

    // 初回取得
    const baseQuery = db.collection("users").where("idPartner", "array-contains", p.currentUser.userCode);

    let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
    try {
      const snapshots = (await baseQuery.get()).docs;
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const userModel = snapshot.data() as UserModel;
        const id = snapshot.id;

        // Queryの"!="で除外してしまうと下記のupdatedAtと合わせて異なるフィールドに
        // 範囲条件を二つ設定することになり、firestoreの制限に引っかかるのでロジックで除外する
        // 監視の方にも同じロジックがあるので注意
        if (p.selfId === id || userModel.isSupport) continue;

        // 卸グループ に存在するユーザーはグループユーザーに含めない
        if (self.oroshiUsers_us[id]) continue;

        const groupUser = logic.getUserData(id, p.selfId, userModel);

        self.setGroupUser_us({ groupUser });

        if (maxUpdatedAt === null || userModel.updatedAt > maxUpdatedAt) {
          maxUpdatedAt = userModel.updatedAt;
        }
      }
    } catch (error) {
      console.log(error);
    }

    // 一括画面反映
    if (self.groupUserInited_us) {
      self.groupUserInited_us(self.groupUsers_us);
    }

    // 変更監視
    const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

    self.setGroupOtherUserUnsubscriber_us({
      groupOtherUserUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const userModel = x.doc.data() as UserModel;
          const id = x.doc.id;

          // 同上
          if (p.selfId === id || userModel.isSupport) return;

          if (self.oroshiUsers_us[id]) return;

          const groupUser = logic.getUserData(id, p.selfId, userModel);

          if (x.type === "added") {
            self.upsertGroupUser_us({ groupUser });
          } else if (x.type === "modified") {
            self.upsertGroupUser_us({ groupUser });
          } else if (x.type === "removed") {
            self.deleteGroupUser_us({ groupUser });
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  private async getSupportUsers(p: { selfId: string }): Promise<void> {
    const self = this;
    const baseQuery = db.collection("users").where("isSupport", "==", true);

    let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
    const supportSnapshots = (await baseQuery.get()).docs;
    for (let i = 0; i < supportSnapshots.length; i++) {
      const snapshot = supportSnapshots[i];
      const userModel = snapshot.data() as UserModel;

      const id = snapshot.id;
      if (p.selfId === id) continue;

      const supportUser = logic.getUserData(id, p.selfId, userModel);

      this.supportUsers_us[supportUser.id] = supportUser;

      if (maxUpdatedAt === null || userModel.updatedAt > maxUpdatedAt) {
        maxUpdatedAt = userModel.updatedAt;
      }
    }

    // 一括画面反映
    if (this.supportUserInited_us) {
      this.supportUserInited_us(this.supportUsers_us);
    }

    // 変更監視
    const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

    self.setSupportUserUnsubscriber_us({
      supportUserUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const userModel = x.doc.data() as UserModel;
          const id = x.doc.id;
          if (p.selfId === id) return;

          const supportUser = logic.getUserData(id, p.selfId, userModel);

          if (x.type === "added") {
            self.upsertSupportUser_us({ supportUser });
          } else if (x.type === "modified") {
            self.upsertSupportUser_us({ supportUser });
          } else if (x.type === "removed") {
            self.deleteSupportUser_us({ supportUser });
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  private async getOroshiUsers(p: { selfId: string; idPartner: string | string[] }): Promise<void> {
    if (!p.idPartner || p.idPartner === "") return;

    const isArray = Array.isArray(p.idPartner);
    if (!isArray) return;
    if (p.idPartner.length === 0) return;

    const self = this;

    let maxUpdatedAt: firebase.firestore.Timestamp | null = null;

    // パートナーIDのユーザーが薬局種別５（卸）の場合、userCodeを取得
    const oroshiUsers: string[] = [];
    const idPartner = p.idPartner.slice(0, 10);

    const oroshiUsersSnapshots = await db.collection("userPrivates").where("userCode", "in", idPartner).get();
    oroshiUsersSnapshots.docs.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data.yakkyokuType === "5") oroshiUsers.push(data.userCode);
      }
    });

    const baseQuery = db.collection("users").where("userCode", "in", idPartner);

    const oroshiSnapshots = (await baseQuery.get()).docs;
    for (let i = 0; i < oroshiSnapshots.length; i++) {
      const snapshot = oroshiSnapshots[i];
      const userModel = snapshot.data() as UserModel;
      const id = snapshot.id;
      if (p.selfId === id || userModel.isSupport) continue;

      // パートナー親、または薬局種別が 5 のユーザー以外は卸グループに含めない
      const isExistOroshi = oroshiUsers.some((oroshiId) => oroshiId === userModel.userCode);
      if (!(userModel.isPartner || isExistOroshi)) continue;

      // 手動でサポートにする
      userModel.isSupport = true;
      const oroshiUser = logic.getUserData(id, p.selfId, userModel);

      this.oroshiUsers_us[oroshiUser.id] = oroshiUser;

      if (maxUpdatedAt === null || userModel.updatedAt > maxUpdatedAt) {
        maxUpdatedAt = userModel.updatedAt;
      }
    }

    // 一括画面反映（2回目）
    if (this.oroshiUserInited_us) {
      this.oroshiUserInited_us(this.oroshiUsers_us);
    }

    // 変更監視
    const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

    self.setOroshiUserUnsubscriber_us({
      oroshiUserUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (x) {
          const userModel = x.doc.data() as UserModel;
          const id = x.doc.id;
          // 同上
          if (p.selfId === id || userModel.isSupport) return;

          const isExistOroshi = oroshiUsers.some((oroshiId) => oroshiId === userModel.userCode);
          if (!(userModel.isPartner || isExistOroshi)) return;

          userModel.isSupport = true;
          const oroshiUser = logic.getUserData(id, p.selfId, userModel);

          if (x.type === "added") {
            self.upsertOroshiUser_us({ oroshiUser });
          } else if (x.type === "modified") {
            self.upsertOroshiUser_us({ oroshiUser });
          } else if (x.type === "removed") {
            self.deleteOroshiUser_us({ oroshiUser });
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  onSelectedChatIdChanged_us(p: { newChatId: string; oldChatId: string }): void {
    if (p.oldChatId) {
      const oldOpponentId = util.getOpponentIdFromChatId(p.oldChatId, this.currentUser_us?.id || "");
      this.setUserIsSelected_us({ userId: oldOpponentId, isSelected: false });
    }

    if (p.newChatId) {
      const newOpponentId = util.getOpponentIdFromChatId(p.newChatId, this.currentUser_us?.id || "");
      this.setUserIsSelected_us({ userId: newOpponentId, isSelected: true });
    }
  }

  @Action({ rawError: true })
  upsertGroupUser_us(p: { groupUser: UserData }): void {
    this.setGroupUser_us({ groupUser: p.groupUser });

    if (this.groupUserUpserted_us) {
      this.groupUserUpserted_us(p.groupUser);
    }
  }

  @Action({ rawError: true })
  deleteGroupUser_us(p: { groupUser: UserData }): void {
    this.unsetGroupUser_us({ groupUser: p.groupUser });

    if (this.groupUserDeleted_us) {
      this.groupUserDeleted_us(p.groupUser);
    }
  }

  @Action({ rawError: true })
  upsertSupportUser_us(p: { supportUser: UserData }): void {
    this.setSupportUser_us({ supportUser: p.supportUser });

    if (this.supportUserUpserted_us) {
      this.supportUserUpserted_us(p.supportUser);
    }
  }

  @Action({ rawError: true })
  deleteSupportUser_us(p: { supportUser: UserData }): void {
    this.unsetSupportUser_us({ supportUser: p.supportUser });

    if (this.supportUserDeleted_us) {
      this.supportUserDeleted_us(p.supportUser);
    }
  }

  @Action({ rawError: true })
  upsertOroshiUser_us(p: { oroshiUser: UserData }): void {
    this.setOroshiUser_us({ oroshiUser: p.oroshiUser });

    if (this.oroshiUserUpserted_us) {
      this.oroshiUserUpserted_us(p.oroshiUser);
    }
  }

  @Action({ rawError: true })
  deleteOroshiUser_us(p: { oroshiUser: UserData }): void {
    this.unsetOroshiUser_us({ oroshiUser: p.oroshiUser });

    if (this.oroshiUserDeleted_us) {
      this.oroshiUserDeleted_us(p.oroshiUser);
    }
  }

  @Action({ rawError: true })
  adjustUserLayer_us(p: { userId: string; userLayer: UserLayerModel | null }) {
    const target = this.groupUsers_us[p.userId];
    if (target) {
      // シャローコピーだが問題はないだろう
      const groupUser = Object.create(target) as UserData;
      groupUser.userLayer = p.userLayer;
      this.upsertGroupUser_us({ groupUser });
    }

    const supportTarget = this.supportUsers_us[p.userId];
    if (supportTarget) {
      const supportUser = Object.create(supportTarget) as UserData;
      supportUser.userLayer = p.userLayer;
      this.upsertSupportUser_us({ supportUser });
    }

    const oroshiTarget = this.oroshiUsers_us[p.userId];
    if (oroshiTarget) {
      const oroshiUser = Object.create(oroshiTarget) as UserData;
      oroshiUser.userLayer = p.userLayer;
      this.upsertOroshiUser_us({ oroshiUser });
    }
  }

  @Action({ rawError: true })
  destroy_us(): void {
    // unsubscriber
    if (this.groupUserUnsubscriber_us) {
      this.groupUserUnsubscriber_us();
    }
    this.setGroupUserUnsubscriber_us({ groupUserUnsubscriber: null });

    if (this.groupOtherUserUnsubscriber_us) {
      this.groupOtherUserUnsubscriber_us();
    }
    this.setGroupOtherUserUnsubscriber_us({ groupOtherUserUnsubscriber: null });

    if (this.supportUserUnsubscriber_us) {
      this.supportUserUnsubscriber_us();
    }
    this.setSupportUserUnsubscriber_us({ supportUserUnsubscriber: null });

    if (this.oroshiUserUnsubscriber_us) {
      this.oroshiUserUnsubscriber_us();
    }
    this.setOroshiUserUnsubscriber_us({ oroshiUserUnsubscriber: null });

    // データと状態
    this.setCurrentUser_us({ currnetUser: null });
    this.setCurrentUserPrivate_us({ currnetPrivateUser: null });

    this.setGroupUserUpserted_us({ groupUserUpserted: null });
    this.setGroupUserDeleted_us({ groupUserDeleted: null });

    this.setGroupUsers_us({ groupUsers: {} });
    if (this.groupUserInited_us) {
      this.groupUserInited_us(this.groupUsers_us);
    }
    this.setGroupUserInited_us({ groupUserInited: null });

    this.setSupportUserUpserted_us({ supportUserUpserted: null });
    this.setSupportUserDeleted_us({ supportUserDeleted: null });

    this.setSupportUsers_us({ supportUsers: {} });
    if (this.supportUserInited_us) {
      this.supportUserInited_us(this.supportUsers_us);
    }
    this.setSupportUserInited_us({ supportUserInited: null });

    this.setOroshiUserUpserted_us({ oroshiUserUpserted: null });
    this.setOroshiUserDeleted_us({ oroshiUserDeleted: null });

    this.setOroshiUsers_us({ oroshiUsers: {} });
    if (this.oroshiUserInited_us) {
      this.oroshiUserInited_us(this.oroshiUsers_us);
    }
    this.setOroshiUserInited_us({ oroshiUserInited: null });
  }

  // データの更新
  // ログインユーザー
  @Mutation
  setCurrentUser_us(p: { currnetUser: UserData | null }) {
    this.currentUser_us = p.currnetUser;
  }

  @Mutation
  setCurrentUserPrivate_us(p: { currnetPrivateUser: UserPrivateData | null }) {
    this.currentUserPrivate_us = p.currnetPrivateUser;
  }

  // グループユーザー
  @Mutation
  private setGroupUsers_us(p: { groupUsers: UserDataTable }) {
    this.groupUsers_us = p.groupUsers;
  }

  @Mutation
  private setGroupUser_us(p: { groupUser: UserData }) {
    const user = this.groupUsers_us[p.groupUser.id];
    this.groupUsers_us[p.groupUser.id] = p.groupUser;
    if (user && user.isSelected) {
      this.groupUsers_us[p.groupUser.id].isSelected = user.isSelected;
    }
  }

  @Mutation
  private unsetGroupUser_us(p: { groupUser: UserData }) {
    delete this.groupUsers_us[p.groupUser.id];
  }

  // サポートユーザー
  @Mutation
  setSupportUsers_us(p: { supportUsers: UserDataTable }) {
    this.supportUsers_us = p.supportUsers;
  }

  @Mutation
  private setSupportUser_us(p: { supportUser: UserData }) {
    const user = this.supportUsers_us[p.supportUser.id];
    this.supportUsers_us[p.supportUser.id] = p.supportUser;
    if (user && user.isSelected) {
      this.supportUsers_us[p.supportUser.id].isSelected = user.isSelected;
    }
  }

  @Mutation
  private unsetSupportUser_us(p: { supportUser: UserData }) {
    delete this.supportUsers_us[p.supportUser.id];
  }

  // 卸ユーザー
  @Mutation
  setOroshiUsers_us(p: { oroshiUsers: UserDataTable }) {
    this.oroshiUsers_us = p.oroshiUsers;
  }

  @Mutation
  private setOroshiUser_us(p: { oroshiUser: UserData }) {
    const user = this.oroshiUsers_us[p.oroshiUser.id];
    this.oroshiUsers_us[p.oroshiUser.id] = p.oroshiUser;
    if (user && user.isSelected) {
      this.oroshiUsers_us[p.oroshiUser.id].isSelected = user.isSelected;
    }
  }

  @Mutation
  private unsetOroshiUser_us(p: { oroshiUser: UserData }) {
    delete this.oroshiUsers_us[p.oroshiUser.id];
  }

  // 選択ユーザー変更
  @Mutation
  private setUserIsSelected_us(p: { userId: string; isSelected: boolean }) {
    if (this.groupUsers_us[p.userId]) {
      this.groupUsers_us[p.userId].isSelected = p.isSelected;
    }

    if (this.supportUsers_us[p.userId]) {
      this.supportUsers_us[p.userId].isSelected = p.isSelected;
    }

    if (this.oroshiUsers_us[p.userId]) {
      this.oroshiUsers_us[p.userId].isSelected = p.isSelected;
    }
  }

  // 状態の更新
  // グループユーザー
  @Mutation
  setGroupUserInited_us(p: { groupUserInited: ((groupUserDataTable: UserDataTable) => void) | null }) {
    this.groupUserInited_us = p.groupUserInited;
  }

  @Mutation
  setGroupUserUpserted_us(p: { groupUserUpserted: ((groupUserData: UserData) => void) | null }) {
    this.groupUserUpserted_us = p.groupUserUpserted;
  }

  @Mutation
  setGroupUserDeleted_us(p: { groupUserDeleted: ((groupUserData: UserData) => void) | null }) {
    this.groupUserDeleted_us = p.groupUserDeleted;
  }

  @Mutation
  private setGroupUserUnsubscriber_us(p: { groupUserUnsubscriber: (() => void) | null }) {
    this.groupUserUnsubscriber_us = p.groupUserUnsubscriber;
  }

  @Mutation
  private setGroupOtherUserUnsubscriber_us(p: { groupOtherUserUnsubscriber: (() => void) | null }) {
    this.groupOtherUserUnsubscriber_us = p.groupOtherUserUnsubscriber;
  }

  // サポートユーザー
  @Mutation
  setSupportUserInited_us(p: { supportUserInited: ((supportUserDataTable: UserDataTable) => void) | null }) {
    this.supportUserInited_us = p.supportUserInited;
  }

  @Mutation
  setSupportUserUpserted_us(p: { supportUserUpserted: ((supportUserData: UserData) => void) | null }) {
    this.supportUserUpserted_us = p.supportUserUpserted;
  }

  @Mutation
  setSupportUserDeleted_us(p: { supportUserDeleted: ((supportUserData: UserData) => void) | null }) {
    this.supportUserDeleted_us = p.supportUserDeleted;
  }

  @Mutation
  private setSupportUserUnsubscriber_us(p: { supportUserUnsubscriber: (() => void) | null }) {
    this.supportUserUnsubscriber_us = p.supportUserUnsubscriber;
  }

  // 卸ユーザー
  @Mutation
  setOroshiUserInited_us(p: { oroshiUserInited: ((oroshiUserDataTable: UserDataTable) => void) | null }) {
    this.oroshiUserInited_us = p.oroshiUserInited;
  }

  @Mutation
  setOroshiUserUpserted_us(p: { oroshiUserUpserted: ((oroshiUserData: UserData) => void) | null }) {
    this.oroshiUserUpserted_us = p.oroshiUserUpserted;
  }

  @Mutation
  setOroshiUserDeleted_us(p: { oroshiUserDeleted: ((oroshiUserData: UserData) => void) | null }) {
    this.oroshiUserDeleted_us = p.oroshiUserDeleted;
  }

  @Mutation
  private setOroshiUserUnsubscriber_us(p: { oroshiUserUnsubscriber: (() => void) | null }) {
    this.oroshiUserUnsubscriber_us = p.oroshiUserUnsubscriber;
  }
}

export const userStore = getModule(UserStore);
