// ビジネスロジックよりのutil
import { db } from "@/common/firebase";
import { UserData } from "./data/UserData";
import { userStore } from "@/store/UserStore";
import { authStore } from "@/store/AuthStore";
import { localStore } from "@/store/LocalStore";
import { commandStore } from "@/store/CommandStore";
import { userLayerStore } from "@/store/UserLayerStore";
import { UserPrivateModel } from "@/shared/model/UserPrivateModel";
import { FDS, FDR } from "./firebaseTypes";
import { UserModel } from "@/shared/model/UserModel";
import { UserPrivateData } from "./data/UserPrivateData";
import { chatStore } from "@/store/ChatStore";
import { chatLayerStore } from "@/store/ChatLayerStore";
import { layerStore } from "@/store/LayerStore";
import { ChatModel, ChatType } from "@/shared/model/ChatModel";
import { ChatData } from "./data/ChatData";
import * as util from "@/common/util";
import { ChatMessageModel } from "@/shared/model/ChatMessageModel";
import { ChatMessageData } from "./data/ChatMessageData";
import { IndividualChat } from "./data/IndividualChat";
import { chatMessageStore } from "@/store/ChatMessageStore";
import { throttle } from "lodash";

export function destroyStores() {
  chatStore.destroy_cs();
  chatLayerStore.destroy_cls();
  userStore.destroy_us();
  userLayerStore.destroy_uls();
  chatMessageStore.destroy_cms();
  layerStore.destroy_ls();
  authStore.destroy_as2();
  commandStore.destroy_cmds();
}

export async function getCurrentUserData(userId: string): Promise<UserData> {
  const userDoc = (await db.doc(`users/${userId}`).get()) as FDS<UserModel>;
  const userModel = userDoc.data();

  if (!userModel) {
    throw new Error("UserModelが存在しません userId: " + userId);
  }

  const userData = new UserData(userId, userId, userModel);
  return userData;
}

export async function getCurrentUserPrivateData(userId: string): Promise<UserPrivateData> {
  const userPrivateDoc = (await db.doc(`userPrivates/${userId}`).get()) as FDS<UserPrivateModel>;
  const userPrivateModel = userPrivateDoc.data();

  if (!userPrivateModel) {
    throw new Error("UserPrivateModelが存在しません userId: " + userId);
  }

  const userPrivateData = new UserPrivateData(userPrivateDoc.id, userPrivateModel);
  return userPrivateData;
}

// userStoreはマスタでもある
export function getUserDataAsMaster(userId: string): UserData | null {
  if (userStore.groupUsers_us[userId]) {
    return userStore.groupUsers_us[userId];
  }

  if (userStore.supportUsers_us[userId]) {
    return userStore.supportUsers_us[userId];
  }

  if (userStore.currentUser_us?.id === userId) {
    return userStore.currentUser_us;
  }

  if (userStore.oroshiUsers_us[userId]) {
    return userStore.oroshiUsers_us[userId];
  }

  return null;
}

export function getUserData(userId: string, selfId: string, model: UserModel): UserData {
  const userData: UserData = new UserData(userId, selfId, model);

  if (userLayerStore.userLayers_uls[userId]) {
    userData.userLayer = userLayerStore.userLayers_uls[userId];
  }

  return userData;
}

export async function getChatData(
  selfId: string,
  selectedChatId: string,
  docRef: FDR<ChatModel>
): Promise<ChatData | null> {
  const chatSnapShot = (await docRef.get()) as FDS<ChatModel>;
  const chatModel = chatSnapShot.data();

  // 万が一サブコレクションはあるが親ドキュメントはないというときでもエラー落ちしないように
  if (!chatModel) return null;

  const chatId = chatSnapShot.id;

  let chatData: ChatData;
  if (chatModel.chatType === "Individual") {
    const opponentId = util.getOpponentIdFromChatId(chatId, selfId);
    chatData = new IndividualChat(chatId, opponentId, selectedChatId, chatModel);
  } else {
    // TODO roomchat
    return null;
  }

  if (chatLayerStore.chatLayers_cls[chatId]) {
    chatData.chatLayer = chatLayerStore.chatLayers_cls[chatId];
  }

  return chatData;
}

export function getChatMessageData(
  chatId: string,
  chatType: ChatType,
  id: string,
  selfId: string,
  chatMessageModel: ChatMessageModel
): ChatMessageData {
  const chatMessageData = new ChatMessageData(id, selfId, chatId, chatType, {
    senderId: chatMessageModel.senderId,
    messageBody: chatMessageModel.messageBody,
    enabled: chatMessageModel.enabled,
    files: chatMessageModel.files,
    createdAt: chatMessageModel.createdAt,
    updatedAt: chatMessageModel.updatedAt,
  });

  return chatMessageData;
}

export function getBaseMessageQuery(chatId: string) {
  return db.collection(`chats/${chatId}/chatMessages`);
}

export function getBaseReadSignQuery(selfId: string, chatId: string) {
  return db.collection(`layers/${selfId}/chatLayers/${chatId}/readSigns`);
}

async function persistNotify() {
  if (localStore.local_lcs.isNotify) {
    // 通知ONなら登録
    await localStore.fetchFcmToken_lcs();
    await localStore.refreshFcmToken_lcs();
  } else {
    // 通知OFFなら削除
    await localStore.deleteFcmToken_lcs();
  }

  localStore.saveLocalStorage_lcs();
}

export const onIsNotifyChangedThrottled = throttle(persistNotify, 1000);
