<template>
  <v-container class="fill-height" fluid style="background: #bdbdbd">
    <v-row align="center" justify="center" style="background: inherite">
      <v-col cols="12" sm="8" md="6" style="background: inherite">
        <!-- v-formの取り扱いに関しては次を参考:https://riotz.works/articles/lopburny/2019/07/31/page-reload-issue-by-implicit-submission/ -->
        <v-form @submit.prevent>
          <v-card class="elevation-8">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>GREEN CHAT ログイン</v-toolbar-title>
            </v-toolbar>

            <!-- labelのアニメーションがAutofillと相性が悪い、見栄えが悪いのでplaceholderを使う -->
            <v-card-text>
              <v-text-field
                placeholder="PDS ID"
                prepend-icon="mdi-account"
                color="primary"
                type="text"
                autocomplete="username"
                ref="inputPdsId"
              ></v-text-field>

              <v-text-field
                placeholder="password"
                prepend-icon="mdi-lock"
                color="primary"
                type="password"
                autocomplete="current-password"
                ref="inputPassword"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <simple-button @click="login">ログイン</simple-button>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { auth, messaging } from "@/common/firebase";
import * as util from "@/common/util";
import SimpleButton from "@/components/SimpleButton.vue";
import { PreLoginCallInput, PreLoginCallOutput } from "@/shared/call/PreLoginCallData";
import { localStore } from "@/store/LocalStore";

@Component({ components: { SimpleButton } })
export default class Login extends Vue {
  // 要注意！
  // v-modelを使うとiosのQuickType barから入力された際になぜか
  // passwordに値が反映されない(inputのvalueには入るが)という
  // おそらくVueのバグというか仕様？があるので(v-modelのinputイベントが発火していない？)
  // 直接値を操作する
  $refs!: {
    inputPdsId: Vue;
    inputPassword: Vue;
  };

  async login() {
    try {
      util.showProgressOverray();

      const pdsId = this.$refs.inputPdsId.$el.querySelector("input")?.value ?? "";
      const password = this.$refs.inputPassword.$el.querySelector("input")?.value ?? "";

      const preLoginResult = await util.firebaseCall<PreLoginCallInput, PreLoginCallOutput>("preLoginCall", {
        pdsId,
        password,
      });

      // ログイン者のIDをsessionStorageに保管
      await localStorage.setItem("loginId", pdsId);

      if (preLoginResult.errorMessage) {
        util.hideProgressOverray();
        await util.showDialog({ title: "ログインに失敗しました", dialogText: preLoginResult.errorMessage });
        return;
      }

      await auth.signInWithEmailAndPassword(preLoginResult.email ?? "", password);

      // web credential 伝達用
      localStore.setNativeCredential_lcs({ credential: { userName: pdsId, password } });
    } catch (error) {
      // ログインで失敗したなら念のためservice worker削除
      // ログイン状態でrevokeRefreshTokensするとなぜかfirebase-message-sw.jsが
      // ログインボタン押下時にrevokeされているにも関わらずregistrationしようとし失敗する
      // service workerを解除する以外の解決が分からなかったので解除する
      // またrevokeに関しては別途解決する(サーバーからfirestore経由でクライアントにコマンドを送信する)
      if (messaging) {
        const swr = await navigator.serviceWorker.getRegistration(
          window.location.origin + "/firebase-cloud-messaging-push-scope"
        );

        if (swr) {
          await swr.unregister();
        }
      }

      throw error;
    } finally {
      util.hideProgressOverray();
    }
  }

  beforeCreate() {
    // console.log("beforeCreate");
    document.body.className = "login-background";

    window.greenchat.sharedWebCredentialGot = async (userName, password) => {
      const inputPdsId = this.$refs.inputPdsId.$el.querySelector("input");
      const inputPassword = this.$refs.inputPassword.$el.querySelector("input");
      if (inputPdsId && inputPassword) {
        inputPdsId.value = userName;
        inputPassword.value = password;
        await this.login();
      }
    };

    util.postMessageToNative("LoginViewAppearing");
  }

  beforeDestroy() {
    window.greenchat.sharedWebCredentialGot = undefined;
  }
}
</script>

<style lang="scss" scoped>
div.v-application-wrap {
  min-height: 90vh;
}
</style>
