import { ChatData } from "@/common/data/ChatData";
import { ChatLayerModel } from "@/shared/model/ChatLayerModel";
import { ChatModel, ChatType, IndividualInfoType } from "@/shared/model/ChatModel";
import * as util from "@/common/util";
import * as logic from "@/common/logic";
import { SendMessageCallInput, SendMessageCallOutput, SendMessageResult } from "@/shared/call/SendMessageCallData";
import { userStore } from "@/store/UserStore";
import firebase from "firebase/compat/app";
import { MessageFileModel } from "@/shared/model/ChatMessageModel";
import { UserData } from "./UserData";

export class IndividualChat implements ChatData {
  id: string;
  individualInfo?: IndividualInfoType;
  chatLayer: ChatLayerModel | null = null;
  chatType: ChatType;
  enabled: boolean;
  updatedAt: firebase.firestore.Timestamp;

  opponentId: string;
  opponentUser: UserData | null;

  isSelected = false;
  lastMessageSnapshotUnscriber: (() => void) | null = null;

  constructor(chatId: string, opponentId: string, selectedChatId: string, model: ChatModel) {
    this.id = chatId;
    this.individualInfo = model.individualInfo;
    this.chatType = model.chatType;
    this.enabled = model.enabled;
    this.updatedAt = model.updatedAt;
    this.isSelected = chatId === selectedChatId;

    this.opponentId = opponentId;
    this.opponentUser = logic.getUserDataAsMaster(opponentId);
  }

  chatName() {
    this.opponentUser = logic.getUserDataAsMaster(this.opponentId);
    if (this.opponentUser) {
      return this.opponentUser.getDisplayName();
    }

    if (this.individualInfo) {
      return this.individualInfo[this.opponentId]?.defaultName ?? "";
    }

    return "";
  }

  imageUri() {
    if (this.opponentUser) {
      return this.opponentUser.imageUri;
    }

    if (this.individualInfo) {
      return this.individualInfo[this.opponentId]?.imageUri ?? "";
    }

    return "";
  }

  color() {
    if (this.opponentUser) {
      return util.getHslColor(this.opponentUser.defaultColor);
    }

    if (this.individualInfo) {
      const defaultColor = util.getHslColor(this.individualInfo[this.opponentId]?.defaultColor ?? []);
      if (defaultColor) {
        return defaultColor;
      }
    }

    return "#f0f0f0";
  }

  textColor() {
    if (this.opponentUser) {
      return util.getHslTextColor(this.opponentUser.defaultColor);
    }

    if (this.individualInfo) {
      const defaultColor = util.getHslTextColor(this.individualInfo[this.opponentId]?.defaultColor ?? []);
      if (defaultColor) {
        return defaultColor;
      }
    }

    return "#606060";
  }

  lastMessageBody() {
    if (this.chatLayer) {
      if (this.chatLayer.lastMessageBody) {
        return this.chatLayer.lastMessageBody;
      }

      if (this.chatLayer.lastFileSenderId) {
        const sender = logic.getUserDataAsMaster(this.chatLayer.lastFileSenderId);
        if (sender) {
          return sender.getDisplayName() + "がファイルを送信しました";
        } else {
          return "ファイルを送信しました";
        }
      }
    }

    return "";
  }

  lastMessageCreatedAt() {
    if (this.chatLayer) {
      return this.chatLayer.lastMessageCreatedAt;
    }

    return null;
  }

  unreadCount() {
    if (this.chatLayer) {
      return this.chatLayer.unreadCount as number;
    }

    return 0;
  }

  unreadCountString() {
    if (this.chatLayer) {
      return util.getUnreadCountString(this.chatLayer.unreadCount as number);
    }

    return "";
  }

  async sendMessage(messageBody: string, files: MessageFileModel[], messageId?: string): Promise<SendMessageResult> {
    const selfId = userStore.currentUser_us?.id;
    if (!selfId) {
      throw new Error("ユーザーデータ不正 sendMessage");
    }

    const sendMessageResult = await util.firebaseCall<SendMessageCallInput, SendMessageCallOutput>("sendMessageCall", {
      senderId: selfId,
      destId: this.opponentId,
      messageBody: messageBody,
      files,
      messageId: messageId,
      loginId: util.getLoginId(),
    });

    return sendMessageResult.resultType;
  }
}
