import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/global.scss";
import store from "./store";
import vuetify from "./plugins/vuetify";
// moment.js は非推奨: https://mag.osdn.jp/20/09/18/113400
import dayjs from "dayjs";
import "dayjs/locale/ja";
import * as util from "@/common/util";

// ios11以下の mobile safari 対応の polyfill
// https://github.com/w3c/IntersectionObserver/tree/master/polyfill
import "intersection-observer";
import "./plugins/observer-visibility";

import { errorStore } from "@/store/ErrorStore";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");

// 共通エラー処理(App.vueに委譲)
// 参考: https://qiita.com/clomie/items/73fa1e9f61e5b88826bc
Vue.config.errorHandler = function(e): void {
  errorStore.errorOccurred_es(e);
};

window.addEventListener("error", event => {
  errorStore.errorOccurred_es(event.error);
});

window.addEventListener("unhandledrejection", event => {
  errorStore.errorOccurred_es(event.reason);
});

// mobile safari のツールバー対策(global.scssも参照)
// 参考: https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
// mobile safari でPWA化したときにwindow.innerHeightがまったくデタラメな値を返すので
// document.documentElement.clientHeightを使用する
// ただしこの場合safe-area-inset-topが引かれた値が返却されているようだ(iPad miniで748や1004など, iPod touch:548)
// viewport-fit=coverを指定しているのにその時点で意味不明だが
// rotationさせるとさらにiPod touch or iPhoneだけ？で568になったりする。全く意味不明
// 様々な方法を試したがmobile safariのheight関連にバグが多すぎるので諦める
// どうしても何かしたいというのであればrotationを検出したらiosだけ
// メッセージ表示してリロードするなどの案もある
window.greenchat.accurateHeightReceived = (height: string) => {
  const vh = Number(height) * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

const setFillHeight = () => {
  if (window.greenchat.nativeType) {
    // mobile safariの初期状態のdocumentElement.clientHeightがおかしな値を返すバグ対応
    util.postMessageToNative("RequestAccurateHeight");
  } else {
    const vh = document.documentElement.clientHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
};

window.addEventListener("resize", setFillHeight);

// 初期化
setFillHeight();

// dayjs日本語設定
dayjs.locale("ja");
