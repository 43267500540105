import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { auth, db } from "@/common/firebase";
import { CommandModel } from "@/shared/model/CommnadModel";
import { userStore } from "./UserStore";

@Module({ dynamic: true, store, name: "commandStore" })
class CommandStore extends VuexModule {
  commandUnsubscriber_cmds: (() => void) | null = null;

  @Action({ rawError: true })
  async observeCommand_cmds(): Promise<void> {
    const self = this;

    // Early Return
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: userStore");
    const selfId = userStore.currentUser_us.id;

    // 即時実行しなくてはならないものは削除しまう
    const notAllowedLazy = await db
      .collection(`users/${selfId}/commands`)
      .where("allowedLazy", "==", false)
      .get();
    await Promise.all(notAllowedLazy.docs.map(doc => doc.ref.delete()));

    // 変更監視
    const observeQuery = db.collection(`users/${selfId}/commands`);

    self.setCommandUnsubscriber_cmds({
      commandUnsubscriber: observeQuery.onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(async function(x) {
          const commandModel = x.doc.data() as CommandModel;

          // とりあえず追加されたときだけ
          if (x.type === "added") {
            // 権限の関係で先に削除する(サインアウトすると削除できない)
            await x.doc.ref.delete();

            switch (commandModel.command) {
              case "signout": {
                await auth.signOut();
                break;
              }
              default: {
                // const _: never = commandModel.command;
                // console.log(_);
              }
            }
          }
        });
      }),
    });
  }

  @Action({ rawError: true })
  destroy_cmds(): void {
    if (this.commandUnsubscriber_cmds) {
      this.commandUnsubscriber_cmds();
    }
    this.setCommandUnsubscriber_cmds({ commandUnsubscriber: null });
  }

  @Mutation
  private setCommandUnsubscriber_cmds(p: { commandUnsubscriber: (() => void) | null }) {
    this.commandUnsubscriber_cmds = p.commandUnsubscriber;
  }
}

export const commandStore = getModule(CommandStore);
