import firebase from "firebase/compat/app";
import { LayerModel } from "@/shared/model/LayerModel";
import { FirestoreModel } from "../FirestoreModel";

export class LayerData implements LayerModel, FirestoreModel {
  id: string;
  totalUnreadCount: number;
  updatedAt: firebase.firestore.Timestamp;

  constructor(id: string, model: LayerModel) {
    this.id = id;
    this.totalUnreadCount = model.totalUnreadCount as number;
    this.updatedAt = model.updatedAt;
  }
}
