import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import { authStore } from "@/store/AuthStore";
import { localStore } from "@/store/LocalStore";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { unnecessaryAuth: true },
  },
  {
    path: "/home/:initialFunction",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/singlepage-message/:initialFunction/:isSameChat",
    name: "ChatMessage",
    component: () => import(/* webpackChunkName: "chatmessage" */ "@/views/ChatMessage.vue"),
    props: { isSinglePage: true },
  },
  {
    path: "/loading",
    name: "Loading",
    component: () => import(/* webpackChunkName: "loading" */ "@/views/Loading.vue"),
  },
  {
    path: "/force-update",
    name: "ForceUpdate",
    component: () => import(/* webpackChunkName: "force-update" */ "@/views/ForceUpdate.vue"),
  },
];

const router = new VueRouter({
  // URLが勝手に変わるとブックマークやリロードでろくなことにならないのでabstractとしたいが、
  // ブラウザバックできなくなるのでhistoryとする
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 初期状態やLoading, Login画面の遷移は履歴には残さない
  // $router.replaceしてもここでちゃんとフラグ設定しないとダメな模様
  const replace = !from.name || ["Loading", "Login"].includes(from.name);

  // アプリバージョンが古いなら強制アップデート
  if (localStore.native_lcs.isSatisfiedVersionCheck === "false") {
    if (to.name === "ForceUpdate") {
      next({ replace });
      return;
    }

    next({ path: "/force-update", query: window.greenchat.inheritSearch, replace });
    return;
  }

  if (to.name === "Loading") {
    // 素通りさせる場合はquery指定は不要
    next({ replace });
    return;
  }

  if (!authStore.isLoadedAuth_as2) {
    next({ path: "/loading", query: window.greenchat.inheritSearch, replace });
    return;
  }

  // 認証が不要なページ、あるいは認証済みならそのまま遷移、そうでないならログインページに戻す
  if (to.matched.some(x => x.meta.unnecessaryAuth) || authStore.currentAuth_as2) {
    next({ replace });
  } else {
    next({ path: "/login", query: window.greenchat.inheritSearch, replace });
  }
});

export default router;
