import { UserPrivateModel } from "@/shared/model/UserPrivateModel";
import firebase from "firebase/compat/app";
import { FirestoreModel } from "../FirestoreModel";

export class UserPrivateData implements UserPrivateModel, FirestoreModel {
  id: string;
  userCode: string;
  pdsId: string;
  kengen: string;
  accountRank: string;
  honbuCompanyCode: string;
  yakkyokuMei: string;
  yakkyokuRyakuMei: string;
  yakkyokuType: string;
  telNo: string;
  faxNo: string;
  kaikyokuShoumeishoHozonPath: string;
  kyokaNo: string;
  kyokaNoYukoKigenFrom: string;
  kyokaNoYukoKigenTo: string;
  enabled: boolean;
  updatedAt: firebase.firestore.Timestamp;

  constructor(id: string, model: UserPrivateModel) {
    this.id = id;
    this.userCode = model.userCode;
    this.pdsId = model.pdsId;
    this.kengen = model.kengen;
    this.accountRank = model.accountRank;
    this.honbuCompanyCode = model.honbuCompanyCode;
    this.yakkyokuMei = model.yakkyokuMei;
    this.yakkyokuRyakuMei = model.yakkyokuRyakuMei;
    this.yakkyokuType = model.yakkyokuType;
    this.telNo = model.telNo;
    this.faxNo = model.faxNo;
    this.kaikyokuShoumeishoHozonPath = model.kaikyokuShoumeishoHozonPath;
    this.kyokaNo = model.kyokaNo;
    this.kyokaNoYukoKigenFrom = model.kyokaNoYukoKigenFrom;
    this.kyokaNoYukoKigenTo = model.kyokaNoYukoKigenTo;
    this.enabled = model.enabled;
    this.updatedAt = model.updatedAt;
  }
}
