import { userStore } from "@/store/UserStore";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { db } from "@/common/firebase";
import { LayerModel } from "@/shared/model/LayerModel";
import { LayerData } from "@/common/data/LayerData";
import firebase from "firebase/compat/app";

@Module({ dynamic: true, store, name: "layerStore" })
class LayerStore extends VuexModule {
  // デフォルトのプロパティがないとリアクティブにならないことに要注意
  layer_ls: LayerData | null = {
    id: "",
    totalUnreadCount: 0,
    updatedAt: new firebase.firestore.Timestamp(0, 0),
  };

  snapshotUnsubscriber_ls: (() => void) | null = null;

  @Action({ rawError: true })
  async initLayers_ls(): Promise<void> {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_ls) return;
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません chatStore");

    const selfId = userStore.currentUser_us.id;

    try {
      // 変更監視
      const observeQuery = db.doc(`layers/${selfId}`);

      self.setSnapshotUnsubscriber_ls({
        snapshotUnsubscriber: observeQuery.onSnapshot(function (x) {
          const layerModel = x.data() as LayerModel;
          if (!layerModel) return;

          const id = x.id;
          const layerData = new LayerData(id, layerModel);

          self.setLayer_ls({ layerData });
        }),
      });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  @Action({ rawError: true })
  destroy_ls() {
    if (this.snapshotUnsubscriber_ls) {
      this.snapshotUnsubscriber_ls();
    }

    this.setSnapshotUnsubscriber_ls({ snapshotUnsubscriber: null });
    this.setLayer_ls({ layerData: null });
  }

  @Mutation
  private setLayer_ls(p: { layerData: LayerData | null }) {
    this.layer_ls = p.layerData;
  }

  @Mutation
  private setSnapshotUnsubscriber_ls(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_ls = p.snapshotUnsubscriber;
  }
}

export const layerStore = getModule(LayerStore);
