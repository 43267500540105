import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import store from "@/store";

@Module({ dynamic: true, store, name: "errorStore" })
class ErrorStore extends VuexModule {
  error_es: Error | null = null;

  @Mutation
  errorOccurred_es(error: Error) {
    this.error_es = error;
  }
}

export const errorStore = getModule(ErrorStore);
