import * as XXH from "xxhashjs";

// 種はなんでもいいと思う
const hashInstance = XXH.h32(0xabcd);

// 冪等性を持たせるためにドキュメントIDは自動発行ではなくキーとなる値から生成する
// firestoreのベストプラクティスによるホットスポット回避のため、ドキュメントIDを分散させる
// 衝突回避で末尾にオリジナルデータを付加する
// Y00000 => abcdefgh_Y00000
export const getDocId = (source: string, separator?: string): string => {
  let sourceHash = hashInstance
    .update(source)
    .digest()
    .toString(16);

  // ゼロ埋め
  sourceHash = ("00000000" + sourceHash).slice(-8);
  return sourceHash + (separator || "") + source;
};

// (abcdefghY00000, ijklmnopY00001) => qrstuvwx_abcdefghY00000-ijklmnopY00001
export const getIndividualChatId = (oneId: string, otherId: string): string => {
  const concat = oneId < otherId ? `${oneId}-${otherId}` : `${otherId}-${oneId}`;
  return getDocId(concat, "_");
};

// (qrstuvwx_abcdefghY00000-ijklmnopY00001, abcdefghY00000) => ijklmnopY00001
export const getOpponentIdFromChatId = (chatId: string, selfId: string): string => {
  const dUnderScore = chatId.split("_");
  if (dUnderScore.length !== 2) {
    return "";
  }

  const dHyphen = dUnderScore[1].split("-");
  if (dHyphen.length !== 2) {
    return "";
  }

  if (dHyphen[0] === selfId) {
    return dHyphen[1];
  }

  if (dHyphen[1] === selfId) {
    return dHyphen[0];
  }

  // 見つからないなら空文字
  return "";
};
