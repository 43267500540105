import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { LocalStorageData } from "@/common/data/LocalStorageData";
import { messaging, vapidKey } from "@/common/firebase";
import { RefreshFcmTokenCallInput, RefreshFcmTokenCallOutput } from "@/shared/call/RefreshFcmTokenCallData";
import { DeleteFcmTokenCallInput, DeleteFcmTokenCallOutput } from "@/shared/call/DeleteFcmTokenCallData";
import * as util from "@/common/util";

@Module({ dynamic: true, store, name: "localStore" })
class LocalStore extends VuexModule {
  private localStorageKey_lcs = "greenchat";

  // デフォルトのプロパティがないとリアクティブにならないことに要注意
  local_lcs: LocalStorageData = { isNotify: false, isNotifyContent: false };

  // FCM Token
  fcmToken_lcs = "";

  // 宛先チャットID(通知をクリックしたとき)
  destChatId_lcs = "";

  // ホーム画面がアクティブかどうか(通知をクリックしたときの挙動変更など用)
  isHomeActivated_lcs = true;

  // native mobile端末用
  native_lcs = {
    // ios, android
    type: "",

    // 1.0.0.xなど
    version: "",

    // granted, denied
    notificationPermission: "",

    fcmToken: "",

    // web credential伝達用
    credential: {
      userName: "",
      password: "",
    },

    // バージョンチェックを満たしているかどうか true, false
    isSatisfiedVersionCheck: "true",
  };

  get fcmTokenEither_lcs() {
    if (this.native_lcs.type) {
      return this.native_lcs.fcmToken;
    }

    return this.fcmToken_lcs;
  }

  isTokenGetSuccess = false;

  @Mutation
  setIsTokenGetSuccess(p: { flg: boolean }) {
    this.isTokenGetSuccess = p.flg;
  }

  @Action
  sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  } //timeはミリ秒

  @Action
  async firebaseTokenSet(timerCount: number) {
    try {
      const fcmToken = await messaging.getToken({ vapidKey });
      this.setFcmToken_lcs({ fcmToken });

      this.setIsTokenGetSuccess({ flg: true });
    } catch (e) {
      // 失敗しても５回までは1秒間隔で実行
      if (timerCount > 4) {
        // ５回失敗でエラーをスロウ
        throw e;
      }
    }
  }

  @Action({ rawError: true })
  async fetchFcmToken_lcs(): Promise<void> {
    if (!messaging || !this.local_lcs.isNotify || Notification.permission !== "granted") return;

    if (!this.fcmToken_lcs) {
      // 初回は絶対失敗するので先に実行(転けなくても正常に動くように)
      try {
        const fcmToken = await messaging.getToken({ vapidKey });
        this.setFcmToken_lcs({ fcmToken });
      } catch {
        // 転けたあとエラーを逃さない
        this.setIsTokenGetSuccess({ flg: false });

        for (let i = 0; i < 5; i++) {
          // 1秒間隔で５回実行
          await this.sleep(1000);
          if (this.isTokenGetSuccess) {
            // 1回でもトークン取得成功したらその後の取得はしない
            break;
          }

          await this.firebaseTokenSet(i);
        }
      }
    }
  }

  @Action({ rawError: true })
  async refreshFcmToken_lcs(): Promise<void> {
    if (!this.local_lcs.isNotify) return;
    if (!this.fcmTokenEither_lcs) return;

    const result = await util.firebaseCall<RefreshFcmTokenCallInput, RefreshFcmTokenCallOutput>("refreshFcmTokenCall", {
      fcmToken: this.fcmTokenEither_lcs,
      isNotifyContent: this.local_lcs.isNotifyContent || false,
    });

    if (result.resultType === "Failed") throw new Error("通知設定の更新に失敗しました");
  }

  @Action({ rawError: true })
  async deleteFcmToken_lcs(): Promise<void> {
    if (!this.fcmTokenEither_lcs) return;

    const result = await util.firebaseCall<DeleteFcmTokenCallInput, DeleteFcmTokenCallOutput>("deleteFcmTokenCall", {
      fcmToken: this.fcmTokenEither_lcs,
    });

    if (result.resultType === "Failed") throw new Error("通知設定の削除に失敗しました");
    this.setFcmToken_lcs({ fcmToken: "" });
  }

  @Mutation
  setIsNotify_lcs(p: { isNotify: boolean }) {
    this.local_lcs.isNotify = p.isNotify;
  }

  @Mutation
  setIsNotifyContent_lcs(p: { isNotifyContent: boolean }) {
    this.local_lcs.isNotifyContent = p.isNotifyContent;
  }

  @Mutation
  loadLocalStorage_lcs() {
    const ls = localStorage.getItem(this.localStorageKey_lcs);
    if (ls) {
      this.local_lcs = JSON.parse(ls);
    }
  }

  @Mutation
  saveLocalStorage_lcs() {
    localStorage.setItem(this.localStorageKey_lcs, JSON.stringify(this.local_lcs));
  }

  @Mutation
  setDestChatId_lcs(p: { destChatId: string }) {
    this.destChatId_lcs = p.destChatId;
  }

  @Mutation
  setIsHomeActivated_lcs(p: { isHomeActivated: boolean }) {
    this.isHomeActivated_lcs = p.isHomeActivated;
  }

  @Mutation
  private setFcmToken_lcs(p: { fcmToken: string }) {
    this.fcmToken_lcs = p.fcmToken;
  }

  @Mutation
  setNativeType_lcs(p: { nativeType: string }) {
    this.native_lcs.type = p.nativeType;
  }

  @Mutation
  setNativeNotificationPermission_lcs(p: { notificationPermission: string }) {
    this.native_lcs.notificationPermission = p.notificationPermission;
  }

  @Mutation
  setNativeFcmToken_lcs(p: { fcmToken: string }) {
    this.native_lcs.fcmToken = p.fcmToken;
  }

  @Mutation
  setNativeCredential_lcs(p: { credential: { userName: string; password: string } }) {
    this.native_lcs.credential = p.credential;
  }

  @Mutation
  setNativeVersion_lcs(p: { version: string }) {
    this.native_lcs.version = p.version;
  }

  @Mutation
  setNativeIsSatisfiedVersionCheck_lcs(p: { isSatisfiedVersionCheck: string }) {
    this.native_lcs.isSatisfiedVersionCheck = p.isSatisfiedVersionCheck;
  }
}

export const localStore = getModule(LocalStore);
