import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { Deferred } from "@/common/Deferred";
import { DialogOption } from "@/common/DialogOption";

@Module({ dynamic: true, store, name: "appStore" })
class AppStore extends VuexModule {
  isDisplayDialog_as1 = false;
  isDisplayOverray_as1 = false;
  dialogOption_as1: DialogOption | null = null;

  private deferred_as1: Deferred<boolean> | null = null;

  @Action({})
  startDialog_as1(dialogOption: DialogOption): Promise<boolean> {
    if (this.deferred_as1 && !this.deferred_as1.isResolved) {
      throw new Error("共通ダイアログを複数表示することはできません");
    }

    this.setDialogOption_as1(dialogOption);
    this.setDeffered_as1(new Deferred<boolean>());
    this.setIsDisplayDialog_as1(true);
    return this.deferred_as1?.promise || Promise.reject();
  }

  @Mutation
  endDialog_as1(dialogResult: boolean): void {
    this.isDisplayDialog_as1 = false;
    this.deferred_as1?.resolve(dialogResult);
  }

  @Mutation
  showProgressOverray_as1(): void {
    this.isDisplayOverray_as1 = true;
  }

  @Mutation
  hideProgressOverray_as1(): void {
    this.isDisplayOverray_as1 = false;
  }

  @Mutation
  setDialogOption_as1(dialogOption: DialogOption): void {
    this.dialogOption_as1 = dialogOption;
  }

  @Mutation
  private setIsDisplayDialog_as1(isDisplayDialog: boolean) {
    this.isDisplayDialog_as1 = isDisplayDialog;
  }

  @Mutation
  private setDeffered_as1(deffered: Deferred<boolean>) {
    this.deferred_as1 = deffered;
  }
}

export const appStore = getModule(AppStore);
